import get from 'lodash/get';
import { takeLatest, put, call } from 'redux-saga/effects';
import {
    getRewardsHistory,
    getActivityHistory,
    getTransactionDetails,
    getMissingPointsHistory,
} from 'yoda-interfaces/lib/Rewards/RewardsApi';
import FactorySaga from 'yoda-site-components/lib/sagas/FactorySaga';
import {
    GET_REWARDS_HISTORY_SUCCESS,
    GET_REWARDS_HISTORY_ERROR,
    GET_ACTIVITY_HISTORY_SUCCESS,
    GET_ACTIVITY_HISTORY_ERROR,
    GET_TRANSACTION_DETAILS_SUCCESS,
    GET_TRANSACTION_DETAILS_ERROR,
    GET_MISSING_POINTS_HISTORY_SUCCESS,
    GET_MISSING_POINTS_HISTORY_ERROR,
    GET_REWARDS_HISTORY_DETAILS,
    GET_ACTIVITY_HISTORY_DETAILS,
    GET_TRANSACTION_DETAILS,
    GET_MISSING_POINTS_DETAILS,
} from '../actionTypes/HistoryActionTypes';

export function* rewardsHistorySaga(action) {
    try {
        const rewardsHistory = get(
            yield call(FactorySaga, getRewardsHistory, action),
            'response',
            {}
        );
        yield put({ type: GET_REWARDS_HISTORY_SUCCESS, rewardsHistory });
    } catch (error) {
        yield put({
            type: GET_REWARDS_HISTORY_ERROR,
            error,
        });
    }
}

export function* activityHistorySaga(action) {
    try {
        const activityHistory = get(
            yield call(FactorySaga, getActivityHistory, action),
            'response',
            {}
        );
        yield put({ type: GET_ACTIVITY_HISTORY_SUCCESS, activityHistory });
    } catch (error) {
        yield put({
            type: GET_ACTIVITY_HISTORY_ERROR,
            error,
        });
    }
}

export function* transactionDetailsSaga(action) {
    try {
        const transactionDetails = get(
            yield call(FactorySaga, getTransactionDetails, action),
            'response',
            {}
        );
        yield put({ type: GET_TRANSACTION_DETAILS_SUCCESS, transactionDetails });
    } catch (error) {
        yield put({
            type: GET_TRANSACTION_DETAILS_ERROR,
            error,
        });
    }
}

export function* missingPointsHistorySaga(action) {
    try {
        const missingPointsHistory = get(
            yield call(FactorySaga, getMissingPointsHistory, action),
            'response',
            {}
        );
        yield put({ type: GET_MISSING_POINTS_HISTORY_SUCCESS, missingPointsHistory });
    } catch (error) {
        yield put({
            type: GET_MISSING_POINTS_HISTORY_ERROR,
            error,
        });
    }
}

export default function* watchHistorySaga() {
    yield takeLatest(GET_REWARDS_HISTORY_DETAILS, rewardsHistorySaga);
    yield takeLatest(GET_ACTIVITY_HISTORY_DETAILS, activityHistorySaga);
    yield takeLatest(GET_TRANSACTION_DETAILS, transactionDetailsSaga);
    yield takeLatest(GET_MISSING_POINTS_DETAILS, missingPointsHistorySaga);
}

watchHistorySaga.sagaName = 'historySaga';
