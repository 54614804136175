import * as types from '../actionTypes/GetAccountTypes';

export default function GetAccountReducer(state = {}, action = {}) {
    switch (action.type) {
        case types.GET_ACCOUNT: {
            const isLoading = true;
            const isSuccess = false;
            return { isLoading, isSuccess };
        }

        case types.GET_ACCOUNT_SUCCESS:
        case types.GET_ACCOUNT_ERROR: {
            return action.payload;
        }

        default:
            return state;
    }
}

GetAccountReducer.reducerName = 'getAccountDetails';
