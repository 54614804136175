import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames/bind';
import isEmpty from 'lodash/isEmpty';

import Icon from 'yoda-core-components/lib/components/Icon/Icon';
import WarningYellow from 'yoda-core-components/lib/assets/svg/WarningYellow.svg';
import Success from 'yoda-core-components/lib/assets/svg/Success.svg';
import Error from 'yoda-core-components/lib/assets/svg/Error.svg';
import Button from 'yoda-core-components/lib/components/Button';
import { dt } from 'yoda-core-components/lib/helpers/Utils/GetTailwindToken';

import * as styles from './RewardsPendingComponent.css';

const cx = classNames.bind(styles);

const RewardsPendingComponent = ({
    activtyMapRegion,
    iconComponent,
    type,
    content,
    hideButton,
    onResend,
    classes,
    buttonTitle,
    footerText,
    message,
}) => {
    const { type: messageType, text } = message;
    const { title, message: subTitle } = content;
    const messageTypeIconMapping = { success: <Success />, error: <Error /> };
    const { wrapper, title: titleClass, description, footerText: footerTextClass } = classes;
    return (
        <div
            data-automation-id="rewards-lnl-pending-button"
            className={cx(
                'rewards-pending-wrapper',
                { 'page-level': type === 'page-level' },
                wrapper
            )}
        >
            <div className={cx('content-container')} id={activtyMapRegion}>
                <Icon iconType="svg" width="48px" height="48px" viewBox="0 0 30 30">
                    {iconComponent}
                </Icon>
                {title && <h2 className={cx('title', titleClass)}>{title}</h2>}
                {subTitle && (
                    <p className={cx('subTitle', description)} data-qm-encrypt="true">
                        {subTitle}
                    </p>
                )}
                <br />
                {!hideButton && (
                    <Button
                        buttonType="Secondary"
                        className={`${cx('resend-button')} ${dt(['mb-2', 'mt-1'])}`}
                        onClick={onResend}
                    >
                        {buttonTitle}
                    </Button>
                )}
                {!isEmpty(message) && (
                    <div>
                        <Icon
                            iconType="svg"
                            width="32px"
                            height="32px"
                            viewBox="0 0 30 30"
                            name={messageType}
                        >
                            {messageTypeIconMapping[messageType]}
                        </Icon>
                        <span className={cx('message', messageType)}>{text}</span>
                    </div>
                )}
                <p className={cx('footerText', footerTextClass)}>{footerText}</p>
            </div>
        </div>
    );
};

RewardsPendingComponent.defaultProps = {
    activtyMapRegion: '',
    message: {},
    footerText: '',
    iconComponent: <WarningYellow />,
    type: 'page-level',
    hideButton: false,
    content: {},
    onResend: () => {},
    classes: {
        wrapper: '',
        button: '',
    },
    buttonTitle: 'Resend Email',
};

RewardsPendingComponent.propTypes = {
    activtyMapRegion: PropTypes.string,
    message: PropTypes.objectOf(PropTypes.string),
    footerText: PropTypes.string,
    iconComponent: PropTypes.node,
    type: PropTypes.string,
    hideButton: PropTypes.bool,
    content: PropTypes.shape({
        title: PropTypes.string,
        message: PropTypes.string,
    }),
    onResend: PropTypes.func,
    classes: PropTypes.objectOf(PropTypes.object),
    buttonTitle: PropTypes.string,
};

export default RewardsPendingComponent;
