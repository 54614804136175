/* istanbul ignore next */
export const staticSuffix = 'rewards'; // eslint-disable-line
export const micrositeName = 'yoda-microsite-rewards'; // eslint-disable-line
export const isContextHeadersRequired = true;
export const configServerSettingsFlag = true;
export const esiHeaderRequiredRoutes = null;

export const cachePolicy = {
    version: 1,
    enabled: false,
    minCache: 0,
};
export const app = {
    logTag: 'MICROSITE:REWARDS',
};
