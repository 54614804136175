import React, { useEffect } from 'react';
import { useLocation, Route, Switch, Redirect } from 'react-router-dom';
import NotFound from 'yoda-app-shell/lib/components/NotFound/NotFound';
import isEmpty from 'lodash/isEmpty';
import includes from 'lodash/includes';
import Layout from 'yoda-site-components/lib/components/Layout/Layout';
import RewardsWrapper from './containers/Pages/Rewards/RewardsWrapper';
import LaunchNLearn from './containers/Pages/LaunchNLearn/LaunchNLearnConnectLazy';
import { scrollToIdBenefits } from './common/Constants';

function ScrollToTop() {
    const { pathname, search } = useLocation();
    const noScrollURL = ['/rewards/rewards/dashboard/history'];
    useEffect(() => {
        const doScrollTo = isEmpty(search) && !includes(noScrollURL, pathname);
        doScrollTo && window.scrollTo(0, 0);
    }, [pathname]);

    return null;
}

export default function App() {
    return (
        <>
            <ScrollToTop />
            <Switch>
                <Redirect
                    exact
                    from="/rewards/rewards/dashboard/benefits"
                    to={`/rewards?scrollId=${scrollToIdBenefits}`}
                />
                {/* eslint-disable react/jsx-props-no-spreading */}
                <Route exact path="/rewards/*" render={(props) => <RewardsWrapper {...props} />} />
                {/* eslint-disable react/jsx-props-no-spreading */}
                <Route exact path="/rewards" render={(props) => <LaunchNLearn {...props} />} />
                {/* eslint-disable react/jsx-props-no-spreading */}
                <Route exact path="/" render={(props) => <LaunchNLearn {...props} />} />

                {/** Default Not Found */}
                <Route render={() => <NotFound />} />
            </Switch>
        </>
    );
}
// this import is to move the common css to the root
// instead of going into lazy loaded css
App.Layout = Layout;
