import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Loader from 'yoda-core-components/lib/components/Loader/Loader';
import classNames from 'classnames/bind';
import get from 'lodash/get';
import Icon from 'yoda-core-components/lib/components/Icon/Icon';
import ChevronRight from 'yoda-core-components/lib/assets/svg/ChevronRight.svg';
import TriangleDownWhite from 'yoda-core-components/lib/assets/icons/navigation/triangle-down.svg';
import TriangleUpWhite from 'yoda-core-components/lib/assets/icons/navigation/triangle-up.svg';
import ChevronLeft from 'yoda-core-components/lib/assets/svg/ChevronLeft.svg';
import { dt } from 'yoda-core-components/lib/helpers/Utils/GetTailwindToken';
import Routing from 'yoda-account-components/lib/helpers/Routing/Routing';
import Rewards from '../../assets/images/rewards_import_point.svg';
import Alerts from '../../assets/images/Alerts.svg';
import HistoryData from './HistoryData';
import ActivityHistoryData from './ActivityHistoryData';
import MissingPointsDetails from './MissingPointsHistoryDetails/MissingPointsDetails';
import ActivityHistoryDetails from './ActivityHistoryDetails/ActivityHistoryDetails';
import { menuFaqRewardsLink, mobileTitle } from '../../common/Constants';
import HistoryHeader from './HistoryHeader';
import * as styles from './History.css';
import { getPageItems, getPageItemsMobile } from '../../common/utils';

const cx = classNames.bind(styles);

class History extends Component {
    static defaultProps = {
        featureFlags: {},
        isNative: false,
        patchHeader: {},
    };

    static propTypes = {
        actions: PropTypes.objectOf(PropTypes.func).isRequired,
        historyDetails: PropTypes.objectOf(PropTypes.object).isRequired,
        activityHistoryDetails: PropTypes.objectOf(PropTypes.object).isRequired,
        transactionDetails: PropTypes.objectOf(PropTypes.object).isRequired,
        deviceType: PropTypes.objectOf(PropTypes.object).isRequired,
        isRewardsHistoryTabEnabled: PropTypes.bool.isRequired,
        isActivityHistoryTabEnabled: PropTypes.bool.isRequired,
        isMissingPointsEnabled: PropTypes.bool.isRequired,
        rewardsCurrentPage: PropTypes.number.isRequired,
        activityCurrentPage: PropTypes.number.isRequired,
        missingCurrentPage: PropTypes.number.isRequired,
        rewardsFilterDates: PropTypes.objectOf(PropTypes.object).isRequired,
        messagesTexts: PropTypes.objectOf(PropTypes.object).isRequired,
        preferences: PropTypes.objectOf(PropTypes.object).isRequired,
        featureFlags: PropTypes.objectOf(PropTypes.object),
        showLoader: PropTypes.bool.isRequired,
        missingPointsHistory: PropTypes.objectOf(PropTypes.object).isRequired,
        isNative: PropTypes.bool,
        patchHeader: PropTypes.func,
    };

    constructor() {
        super();
        this.state = {
            sortingClassName: 'descend',
            limit: 10,
            selectedHistoryFilter: 30,
            selectedActivityFilter: 30,
            selectedHistoryFilterText: 'Last 30 days',
            selectedActivityFilterText: 'Last 30 days',
            analyticsValue: 0,
            showTransactionDetails: false,
            activityFilterDates: [],
            rewardsCurrentPage: 1,
            missingCurrentPage: 1,
            activityCurrentPage: 1,
        };
        this.dateSortClicked = this.dateSortClicked.bind(this);
        this.historyTabClicked = this.historyTabClicked.bind(this);
        this.pageNoDropdownChange = this.pageNoDropdownChange.bind(this);
        this.filterOptionChange = this.filterOptionChange.bind(this);
        this.isNavButtonEnbled = this.isNavButtonEnbled.bind(this);
        this.historyEmpty = this.historyEmpty.bind(this);
    }

    /* eslint-disable camelcase */
    UNSAFE_componentWillMount() {
        // document.getElementsByTagName('footer')[0].childNodes[1].children[0].setAttribute('style', 'float: left;');
        const { actions, rewardsCurrentPage } = this.props;
        const { limit } = this.state;
        actions.getRewardsHistory(rewardsCurrentPage, limit, '');
        actions.getRewardsHistoryPL('rewards history');
    }

    /* eslint-disable camelcase */
    UNSAFE_componentWillReceiveProps(nextProps) {
        /* istanbul ignore next */
        const {
            historyDetails,
            actions,
            activityHistoryDetails,
            isRewardsHistoryTabEnabled,
            missingPointsHistory,
        } = nextProps;
        activityHistoryDetails && this.prepareHistory(activityHistoryDetails);
        missingPointsHistory && this.prepareHistory(missingPointsHistory);
        historyDetails && this.prepareHistory(historyDetails);
        /* istanbul ignore next */
        const { analyticsValue } = this.state;
        /* istanbul ignore next */
        if (analyticsValue) {
            let selectedTabData = activityHistoryDetails || {};
            let pageName = 'activity history';
            if (isRewardsHistoryTabEnabled) {
                selectedTabData = historyDetails || {};
                pageName = 'rewards history';
            }
            if (selectedTabData.limit) {
                const numberOfResults = isRewardsHistoryTabEnabled
                    ? String(historyDetails.numberOfCerts)
                    : String(activityHistoryDetails.numberOfActivities);
                if (analyticsValue === 1) {
                    actions.getHistoryPaginationData({
                        numberOfResults,
                        pageName,
                        resultsPageNumber: String(selectedTabData.page),
                        resultsPerPage: String(selectedTabData.limit),
                        sortByType: isRewardsHistoryTabEnabled ? 'expiration date' : 'date',
                    });
                } else if (analyticsValue === 2) {
                    const filter = isRewardsHistoryTabEnabled
                        ? this.state.selectedHistoryFilter
                        : this.state.selectedActivityFilter;
                    const sortByType = isRewardsHistoryTabEnabled
                        ? this.state.selectedHistoryFilterText
                        : this.state.selectedActivityFilterText;
                    const analyticsData = {
                        filterKey: '',
                        filterKeyPosition: String(filter),
                        valueName: filter,
                        valuePosition: String(filter),
                        lastFilterName: '',
                        lastFilterValue: '',
                        sortByType,
                        numberOfResults,
                        pageName,
                        resultsPageNumber: '1',
                        resultsPerPage: String(selectedTabData.limit),
                    };
                    actions.getHistoryFilterData(analyticsData);
                } else {
                    const analyticsData = {
                        sortByType: isRewardsHistoryTabEnabled ? 'expiration date' : 'date',
                        numberOfResults,
                        pageName,
                        resultsPageNumber: String(selectedTabData.page),
                        resultsPerPage: String(selectedTabData.limit),
                    };
                    actions.getHistorySortByDate(analyticsData);
                }
                this.setState({ analyticsValue: 0 });
            }
        }
    }

    componentWillUnmount() {
        this.props.actions.setDefaultTabHistory('default');
    }

    reditectToLinkCard = () => {
        if (window?.location?.pathname?.split('/').indexOf('history') > 0) {
            Routing.redirect('link-credit-card', true);
        } else {
            Routing.redirect('dashboard/link-credit-card', true);
        }
    };

    prepareHistory = (historyData) => {
        const { page, transactions, filterDates, data, rewardsHistory } = historyData;
        if (transactions) {
            page && this.setState({ activityCurrentPage: page });
            filterDates && this.setState({ activityFilterDates: filterDates });
            return true;
        }
        if (rewardsHistory) {
            page && this.setState({ rewardsCurrentPage: page });
            filterDates && this.setState({ activityFilterDates: filterDates });
            return true;
        }
        if (data) {
            page && this.setState({ missingCurrentPage: page });
            return true;
        }
        return false;
    };

    onCloseTransactionsDetails = () => {
        this.setState({ showTransactionDetails: false });
    };

    getTransactionDetailsTrigger = (activityData) => {
        this.setState({ showTransactionDetails: true, activityData });
        const pageName = 'View rewards transaction details - modal';
        this.props.actions.transactionDetailsModal(pageName);
    };

    displayPaginationItems = (totalPages, currentPageValue) => {
        const { deviceType } = this.props;
        const arrayItems = deviceType?.isDesktop
            ? getPageItems(totalPages, currentPageValue)
            : getPageItemsMobile(totalPages, currentPageValue);
        const items = [];

        for (let i = 0; i < arrayItems.length; i += 1) {
            const paginationTextItemActiveClasses = dt(['bg-gray-70', 'rounded-sm']);

            const paginationTextItemClasses = `${dt([
                'font-sans',
                'font-semibold',
                'no-underline',
                'w-8',
                'text-center',
                'lg:text-xlarge',
                'sm:text-normal',
                'float-left',
                'md:py-0.5',
                'px-0',
                'smOnly:mr-1',
                'md:mr-3',
                'smOnly:py-1.5',
                'last:!mr-0',
            ])} ${
                arrayItems[i] === 'start-ellipsis' || arrayItems[i] === 'end-ellipsis'
                    ? dt(['cursor-default'])
                    : dt(['cursor-pointer'])
            } ${
                currentPageValue === arrayItems[i]
                    ? dt(['text-white'])
                    : dt(['text-gray-70', 'w-8'])
            }`;

            if (arrayItems[i] === 'start-ellipsis' || arrayItems[i] === 'end-ellipsis') {
                items.push(<div className={paginationTextItemClasses}>...</div>);
            } else
                items.push(
                    <button
                        onClick={(e) => this.pageNoDropdownChange(e, arrayItems[i])}
                        className={`${
                            currentPageValue === arrayItems[i]
                                ? paginationTextItemActiveClasses
                                : ''
                        } ${paginationTextItemClasses}`}
                        type="button"
                    >
                        {arrayItems[i]}
                    </button>
                );
        }
        return items;
    };

    historyEmpty = () => {
        return (
            <>
                <div
                    className={dt(['mt-10', 'mx-auto', 'text-xlarge', '!font-bold', 'text-center'])}
                >
                    There is no history to view in this date range.
                </div>
                <div className={dt(['mb-10', 'mt-4', 'mx-auto', 'text-normal', 'text-center'])}>
                    Please try another option.
                </div>
            </>
        );
    };

    historyTabClicked(value) {
        const {
            historyDetails,
            activityHistoryDetails,
            rewardsCurrentPage,
            activityCurrentPage,
            missingCurrentPage,
            actions,
        } = this.props;
        const { limit } = this.state;
        if (value === 'rewards') {
            actions.setRewardsTabHistory(true);
            if (!historyDetails || !historyDetails.rewardsHistory) {
                actions.getRewardsHistory(rewardsCurrentPage, limit, '');
            }
            actions.getRewardsHistoryPL('rewards history');
        } else if (value === 'activity') {
            actions.setActivityTabHistory(true);
            if (!activityHistoryDetails || !activityHistoryDetails.transactions) {
                actions.getActivityHistory(activityCurrentPage, limit, '');
            }
            actions.getActivityHistoryPL('activity history');
        } else {
            // request for each time they click on tab
            actions.setMissingTabHistory(true);
            actions.getMissingPointsHistory(missingCurrentPage, limit);
            actions.missingPointsLoadAction();
        }
    }

    dateSortClicked() {
        // var element = event.currentTarget;
        const {
            isRewardsHistoryTabEnabled,
            rewardsCurrentPage,
            activityCurrentPage,
            historyDetails,
            activityHistoryDetails,
            actions,
        } = this.props;
        const { limit, selectedHistoryFilter, selectedActivityFilter } = this.state;
        const params = { sortingClassName: '' };
        if (isRewardsHistoryTabEnabled) {
            if (historyDetails.rewardsHistory && historyDetails.rewardsHistory.length > 0) {
                this.state.sortingClassName === 'descend'
                    ? (params.sortingClassName = 'ascend')
                    : (params.sortingClassName = 'descend');
                actions.getRewardsHistory(
                    rewardsCurrentPage,
                    limit,
                    selectedHistoryFilter,
                    params.sortingClassName
                );
                this.setState({ sortingClassName: params.sortingClassName });
            }
        } else if (
            activityHistoryDetails.transactions &&
            activityHistoryDetails.transactions.length > 0
        ) {
            this.state.sortingClassName === 'descend'
                ? (params.sortingClassName = 'ascend')
                : (params.sortingClassName = 'descend');
            actions.getActivityHistory(
                activityCurrentPage,
                limit,
                selectedActivityFilter,
                params.sortingClassName
            );
            this.setState({ sortingClassName: params.sortingClassName });
        }
        this.setState({ analyticsValue: 3 });
    }

    pageNoDropdownChange(event = { currentTarget: {}, target: {} }, selectedValue) {
        let from = '';
        let selectedPageNo;
        const {
            isRewardsHistoryTabEnabled,
            actions,
            isMissingPointsEnabled,
            missingPointsHistory: { page: missingCurrentPage = 1 },
            deviceType,
            historyDetails: { page: rewardsCurrentPage = 1 },
            activityHistoryDetails: { page: activityCurrentPage = 1 },
        } = this.props;
        const { limit, selectedHistoryFilter, selectedActivityFilter } = this.state;
        if (!selectedValue && typeof event.currentTarget.getAttribute === 'function') {
            from = event.currentTarget.getAttribute('data-from');
        }
        /* istanbul ignore if */
        if (selectedValue) {
            selectedPageNo = selectedValue;
        } else if (!from) {
            selectedPageNo = parseInt(event.target.value, 10);
        } else if (isRewardsHistoryTabEnabled) {
            if (from === 'next') {
                if (rewardsCurrentPage >= 1) {
                    selectedPageNo = rewardsCurrentPage + 1;
                }
            } else if (rewardsCurrentPage > 1) {
                selectedPageNo = rewardsCurrentPage - 1;
            }
        } else if (isMissingPointsEnabled) {
            if (from === 'next') {
                if (missingCurrentPage >= 1) {
                    selectedPageNo = missingCurrentPage + 1;
                }
            } else if (missingCurrentPage > 1) {
                selectedPageNo = missingCurrentPage - 1;
            }
        } else if (from === 'next') {
            if (activityCurrentPage >= 1) {
                selectedPageNo = activityCurrentPage + 1;
            }
        } else if (activityCurrentPage > 1) {
            selectedPageNo = activityCurrentPage - 1;
        }
        if (isRewardsHistoryTabEnabled) {
            actions.getRewardsHistory(selectedPageNo, limit, selectedHistoryFilter);
        } else if (isMissingPointsEnabled) {
            actions.getMissingPointsHistory(selectedPageNo, limit);
        } else {
            actions.getActivityHistory(selectedPageNo, limit, selectedActivityFilter);
        }
        this.setState({ analyticsValue: 1 });
        if (deviceType.isMobile) {
            const mobileScrollTab = document.getElementById('mobileScrollTab');
            mobileScrollTab && mobileScrollTab.scrollIntoView();
        }
    }

    filterOptionChange(event) {
        const filterValue = event.target.value;
        const filter = Number(filterValue);
        const selectedFilterText = event.target.options[event.target.selectedIndex].innerText;
        const { isRewardsHistoryTabEnabled, actions } = this.props;
        if (isRewardsHistoryTabEnabled) {
            this.setState({
                selectedHistoryFilter: filter,
                selectedHistoryFilterText: selectedFilterText,
            });
        } else {
            this.setState({
                selectedActivityFilter: filter,
                selectedActivityFilterText: selectedFilterText,
            });
        }
        const { limit } = this.state;
        this.setState({ analyticsValue: 2 });
        if (isRewardsHistoryTabEnabled) {
            actions.getRewardsHistory('1', limit, filter);
            actions.getRewardsHistoryPL('rewards history');
        } else {
            actions.getActivityHistory('1', limit, filter);
            actions.getActivityHistoryPL('activity history');
        }
    }

    isNavButtonEnbled(direction) {
        const { isRewardsHistoryTabEnabled, isMissingPointsEnabled } = this.props;
        let history = {};
        if (isRewardsHistoryTabEnabled) {
            history = this.props.historyDetails || {};
        } else if (isMissingPointsEnabled) {
            history = this.props.missingPointsHistory || {};
        } else {
            history = this.props.activityHistoryDetails || {};
        }

        if (direction === 'left') {
            return history.page < 2;
        }
        return history.pages === 0 || history.page === history.pages;
    }

    render() {
        const {
            historyDetails,
            activityHistoryDetails = {},
            transactionDetails,
            isRewardsHistoryTabEnabled,
            isActivityHistoryTabEnabled,
            deviceType,
            rewardsFilterDates,
            actions,
            messagesTexts,
            preferences,
            featureFlags,
            showLoader,
            isMissingPointsEnabled,
            missingPointsHistory,
            isNative,
            patchHeader,
        } = this.props;

        const {
            selectedHistoryFilter,
            selectedActivityFilter,
            showTransactionDetails,
            activityData,
            activityFilterDates,
            rewardsCurrentPage,
            missingCurrentPage,
            activityCurrentPage,
        } = this.state;

        const { isMissingTabEnabled } = featureFlags;
        const rewardsHistoryClass =
            isRewardsHistoryTabEnabled && !isMissingPointsEnabled ? 'rewards' : '';
        const activityHistoryClass =
            !isRewardsHistoryTabEnabled && isActivityHistoryTabEnabled ? 'activity' : '';
        const missingPointsClass = isMissingPointsEnabled ? 'missing' : '';
        let currentPageValue;
        if (isRewardsHistoryTabEnabled) {
            currentPageValue = rewardsCurrentPage;
        } else if (isMissingPointsEnabled) {
            currentPageValue = missingCurrentPage;
        } else {
            currentPageValue = activityCurrentPage;
        }
        const totalNumberOfPages = [];
        let pages = 0;
        const recentFilterValue = isRewardsHistoryTabEnabled
            ? selectedHistoryFilter
            : selectedActivityFilter;
        const filterDates = isRewardsHistoryTabEnabled ? rewardsFilterDates : activityFilterDates;
        if (isRewardsHistoryTabEnabled) {
            if (historyDetails && historyDetails.pages > 0) {
                pages = historyDetails.pages;
                for (let idx = 1; idx <= historyDetails.pages; idx += 1) {
                    totalNumberOfPages.push(<option value={idx}>{idx}</option>);
                }
            } else {
                totalNumberOfPages.push(<option value="0">0</option>);
            }
        } else if (isMissingPointsEnabled) {
            if (missingPointsHistory && missingPointsHistory.pages > 0) {
                pages = missingPointsHistory.pages;
                for (let idx = 1; idx <= missingPointsHistory.pages; idx += 1) {
                    totalNumberOfPages.push(<option value={idx}>{idx}</option>);
                }
            } else {
                totalNumberOfPages.push(<option value="0">0</option>);
            }
        } else if (activityHistoryDetails && activityHistoryDetails.pages > 0) {
            pages = activityHistoryDetails.pages;
            for (let idx = 1; idx <= activityHistoryDetails.pages; idx += 1) {
                totalNumberOfPages.push(<option value={idx}>{idx}</option>);
            }
        } else {
            totalNumberOfPages.push(<option value="0">0</option>);
        }

        const isLeftNavButtonEnbled = this.isNavButtonEnbled('left');
        const isRightNavButtonEnbled = this.isNavButtonEnbled('right');
        const tableCellCommonStyles = dt([
            'border-b',
            'border-solid',
            'border-gray-15',
            'table-cell',
            'py-[5px]',
            'px-2.5',
            'min-w-[auto]',
            'max-w-[250px]',
            'smMd:min-w-[50px]',
            'bg-black',
            'text-small',
            'text-white',
            'align-middle',
        ]);
        const hideIsNative = get(featureFlags, 'enableRewardsWebview', true) && isNative;

        return (
            <section>
                {showLoader && <Loader keepOverlay automationId="test-automation-loader-1" />}
                <div
                    className={`${cx('rewardsRightPanel')} ${dt([
                        'float-left',
                        hideIsNative ? 'pt-0' : 'pt-8',
                        'px-0',
                        'pb-2',
                        hideIsNative ? 'mt-0' : 'mt-2',
                        'mb-2',
                        'bg-white',
                        'w-full',
                        'smOnly:px-0',
                    ])}`}
                    id="historyPanel"
                    data-automation-id={deviceType.isMobile ? 'history_header' : null}
                >
                    <HistoryHeader
                        isMissingPointsEnabled={isMissingPointsEnabled}
                        isMissingTabEnabled={isMissingTabEnabled}
                        rewardsHistoryClass={rewardsHistoryClass}
                        activityHistoryClass={activityHistoryClass}
                        missingPointsClass={missingPointsClass}
                        filterDates={filterDates}
                        rewardsTitleData={hideIsNative ? mobileTitle.slice(0, -1) : mobileTitle}
                        recentFilterValue={recentFilterValue}
                        historyTabClicked={this.historyTabClicked}
                        filterOptionChange={this.filterOptionChange}
                    />
                    {historyDetails && isRewardsHistoryTabEnabled && !isMissingPointsEnabled ? (
                        <div>
                            <div
                                className={`${cx('rewardsActivityHistory')} ${dt([
                                    'float-left',
                                    'w-full',
                                    // 'mb-2',
                                    'smMd:overflow-hidden',
                                    'smOnly:mt-4',
                                ])}`}
                            >
                                <div className={`${cx('divTable')} ${dt(['table', 'w-full'])}`}>
                                    <div
                                        className={`${cx('divTableHead')} ${dt([
                                            'table-row-group',
                                            'smMd:min-w-[50px]',
                                            'first:border-l',
                                            'first:border-solid',
                                            'first:border-gray-15',
                                            'first:smMd:min-w-[50px]',
                                            'last:border-r',
                                            'last:border-solid',
                                            'last:border-gray-15',
                                        ])}`}
                                        data-automation-id="rewards_history_header"
                                    >
                                        <div
                                            className={`${cx('divTableRow')} ${dt([
                                                'table-row',
                                                'bg-white h-8',
                                            ])}`}
                                        >
                                            <div
                                                className={`${cx(
                                                    'divTableCell',
                                                    'expDate',
                                                    tableCellCommonStyles
                                                )} ${dt([
                                                    '!pl-4',
                                                    '!pr-0',
                                                    'smOnly:!pr-2',
                                                    'smOnly:!pl-4',
                                                ])}`}
                                            >
                                                <button
                                                    onClick={this.dateSortClicked}
                                                    type="button"
                                                >
                                                    Expiration Date
                                                    {this.state.sortingClassName === 'descend' ? (
                                                        <span className={dt(['pl-2'])}>
                                                            <Icon
                                                                iconType="svg"
                                                                width="16px"
                                                                height="16px"
                                                                viewBox="0 0 16 16"
                                                            >
                                                                <TriangleDownWhite />
                                                            </Icon>
                                                        </span>
                                                    ) : (
                                                        <span className={dt(['pl-1'])}>
                                                            <Icon
                                                                iconType="svg"
                                                                width="16px"
                                                                height="16px"
                                                                viewBox="0 0 16 16"
                                                            >
                                                                <TriangleUpWhite />
                                                            </Icon>
                                                        </span>
                                                    )}
                                                </button>
                                            </div>

                                            <div
                                                className={`${cx(
                                                    'divTableCell',
                                                    tableCellCommonStyles
                                                )} ${dt(['smMd:!hidden', 'smOnly:text-center'])}`}
                                            >
                                                Rewards Type
                                            </div>
                                            <div
                                                className={`${cx(
                                                    'divTableCell',
                                                    tableCellCommonStyles
                                                )} ${dt(['smOnly:!hidden', 'smOnly:text-center'])}`}
                                            >
                                                Status
                                            </div>
                                            <div
                                                className={`${cx(
                                                    'divTableCell',
                                                    tableCellCommonStyles
                                                )} ${dt(['text-center'])}`}
                                            >
                                                Value
                                            </div>
                                        </div>
                                    </div>
                                    {historyDetails?.rewardsHistory?.length > 0 && (
                                        <HistoryData
                                            isMobile={deviceType.isMobile}
                                            isDesktop={deviceType.isDesktop}
                                            data={historyDetails.rewardsHistory}
                                        />
                                    )}
                                </div>
                                {historyDetails?.rewardsHistory?.length === 0 &&
                                    this.historyEmpty()}
                            </div>
                        </div>
                    ) : (
                        ''
                    )}
                    {historyDetails && !isRewardsHistoryTabEnabled && isActivityHistoryTabEnabled && (
                        <div
                            className={`${cx('rewardsActivityHistory', 'activityHistoryTab')} ${dt([
                                'float-left',
                                'w-full',
                                'smMd:overflow-hidden',
                                'smOnly:mt-4',
                            ])}`}
                        >
                            <div className={`${cx('divTable')} ${dt(['table', 'w-full'])}`}>
                                <div
                                    className={`${cx('divTableHead')} ${dt([
                                        'table-row-group',
                                        'smMd:min-w-[50px]',
                                        'first:border-l',
                                        'first:border-solid',
                                        'first:border-gray-15',
                                        'first:smMd:min-w-[50px]',
                                        'last:border-r',
                                        'last:border-solid',
                                        'last:border-gray-15',
                                    ])}`}
                                    data-automation-id="activity_history_header"
                                >
                                    <div
                                        className={`${cx('divTableRow')} ${dt([
                                            'table-row',
                                            'bg-white h-8',
                                        ])}`}
                                    >
                                        <div
                                            className={`${cx(
                                                'divTableCell',
                                                tableCellCommonStyles
                                            )} ${dt([
                                                'last:text-center',
                                                '!pl-4',
                                                'mdOnly:w-3/12',
                                            ])}`}
                                        >
                                            <button type="button" onClick={this.dateSortClicked}>
                                                Date
                                                {this.state.sortingClassName === 'descend' ? (
                                                    <span className={dt(['pl-2'])}>
                                                        <Icon
                                                            iconType="svg"
                                                            width="16px"
                                                            height="16px"
                                                            viewBox="0 0 16 17"
                                                        >
                                                            <TriangleDownWhite />
                                                        </Icon>
                                                    </span>
                                                ) : (
                                                    <span className={dt(['pl-1'])}>
                                                        <Icon
                                                            iconType="svg"
                                                            width="16px"
                                                            height="16px"
                                                            viewBox="0 0 16 16"
                                                        >
                                                            <TriangleUpWhite />
                                                        </Icon>
                                                    </span>
                                                )}
                                            </button>
                                        </div>
                                        <div
                                            className={`${cx(
                                                'divTableCell',
                                                tableCellCommonStyles
                                            )} ${dt([
                                                'smMd:!hidden',
                                                'last:text-center',
                                                'smOnly:text-center',
                                                'mdOnly:w-3/12',
                                            ])}`}
                                        >
                                            Transaction
                                        </div>
                                        <div
                                            className={`${cx(
                                                'divTableCell',
                                                tableCellCommonStyles
                                            )} ${dt([
                                                'smOnly:!hidden',
                                                'last:text-center',
                                                'smOnly:text-center',
                                                'mdOnly:w-3/12',
                                            ])}`}
                                        >
                                            Description
                                        </div>
                                        <div
                                            className={`${cx(
                                                'divTableCell',
                                                tableCellCommonStyles
                                            )} ${dt([
                                                '!py-0',
                                                'last:text-center',
                                                'smOnly:text-center',
                                                'mdOnly:w-3/12',
                                            ])}`}
                                        >
                                            Points
                                        </div>
                                    </div>
                                </div>
                                {activityHistoryDetails?.transactions?.length > 0 && (
                                    <ActivityHistoryData
                                        data={activityHistoryDetails.transactions}
                                        isMobile={deviceType.isMobile}
                                        isDesktop={deviceType.isDesktop}
                                        onGetDetails={this.getTransactionDetailsTrigger}
                                        messagesTexts={messagesTexts}
                                    />
                                )}
                            </div>
                            {activityHistoryDetails?.transactions?.length === 0 &&
                                this.historyEmpty()}
                            {showTransactionDetails ? (
                                <ActivityHistoryDetails
                                    getTransactionDetailsAction={actions.getTransactionDetails}
                                    showTransactionDetails={showTransactionDetails}
                                    onCloseTransactionsDetails={this.onCloseTransactionsDetails}
                                    transactionDetails={transactionDetails}
                                    messagesTexts={messagesTexts}
                                    preferences={preferences}
                                    activityData={activityData}
                                    patchHeader={patchHeader}
                                    isNative={isNative}
                                    featureFlags={featureFlags}
                                />
                            ) : null}
                        </div>
                    )}
                    {featureFlags.isMissingTabEnabled && isMissingPointsEnabled && (
                        <div
                            className={`${cx('rewardsActivityHistory', 'activityHistoryTab')} ${dt([
                                'float-left',
                                'w-full',
                                // 'mb-2',
                                'smMd:overflow-hidden',
                                'smOnly:mt4',
                            ])}`}
                        >
                            <div className={`${cx('divTable')} ${dt(['table', 'w-full'])}`}>
                                <div
                                    className={`${cx('divTableHead')} ${dt([
                                        'table-row-group',
                                        'smMd:min-w-[50px]',
                                    ])}`}
                                    data-automation-id="missing_points_header"
                                >
                                    <div
                                        className={`${cx('divTableRow', 'missingTableRow')} ${dt([
                                            'table-row',
                                            'bg-white h-8',
                                        ])}`}
                                    >
                                        <div
                                            className={`${cx(
                                                'divTableCell',
                                                tableCellCommonStyles
                                            )} ${dt(['mdOnly:w-3/12', '!pl-4'])}`}
                                            data-automation-id="missing_request_date"
                                        >
                                            <p>Request Date</p>
                                        </div>
                                        <div
                                            className={`${cx(
                                                'divTableCell',
                                                tableCellCommonStyles
                                            )} ${dt(['mdOnly:w-3/12', 'smMd:!hidden'])}`}
                                            data-automation-id="missing_receipt_number"
                                        >
                                            Receipt Number
                                        </div>
                                        <div
                                            className={`${cx(
                                                'divTableCell',
                                                tableCellCommonStyles
                                            )} ${dt([
                                                'mdOnly:w-3/12',
                                                'smOnly:!hidden',
                                                'smOnly:text-center',
                                            ])}`}
                                            data-automation-id="missing_status"
                                        >
                                            <span>Status</span>
                                        </div>
                                        <div
                                            className={`${cx(
                                                'divTableCell',
                                                tableCellCommonStyles
                                            )} ${dt([
                                                'mdOnly:w-3/12',
                                                'last:text-center',
                                                'smOnly:text-center',
                                            ])}`}
                                            data-automation-id="missing_receipt_total"
                                        >
                                            <span className={dt(['smOnly:hidden'])}>
                                                Receipt Total
                                            </span>
                                            <span className={dt(['visbileSm'])}>Total</span>
                                        </div>
                                    </div>
                                </div>
                                <MissingPointsDetails
                                    missingPointsHistory={missingPointsHistory}
                                    messagesTexts={messagesTexts}
                                    isMobile={deviceType.isMobile}
                                />
                            </div>
                        </div>
                    )}
                    {(isMissingPointsEnabled ||
                        isActivityHistoryTabEnabled ||
                        isRewardsHistoryTabEnabled) &&
                        pages > 1 && (
                            <div
                                className={`${cx('pagination')}  ${dt([
                                    'w-full',
                                    'float-left',
                                    'mt-4',
                                    'px-0',
                                ])}`}
                            >
                                <div
                                    className={`${cx('changePageItem')} ${dt([
                                        'smOnly:w-full',
                                        'flex',
                                        'justify-center',
                                        'items-center',
                                    ])}`}
                                >
                                    <div
                                        className={`${cx(
                                            'prev',
                                            isLeftNavButtonEnbled && 'disabled'
                                        )} ${dt([
                                            isLeftNavButtonEnbled && 'opacity-30',
                                            'lg:h-8',
                                            'smOnly:mr-1',
                                            'md:mr-3',
                                        ])}`}
                                        ref={(component) => {
                                            this.prevDiv = component;
                                        }}
                                    >
                                        {!isLeftNavButtonEnbled && (
                                            <button
                                                type="button"
                                                onClick={this.pageNoDropdownChange}
                                                data-from="prev"
                                                disabled={isLeftNavButtonEnbled}
                                            >
                                                <Icon
                                                    className={dt(['align-top'])}
                                                    iconType="svg"
                                                    width={deviceType.isDesktop ? '32' : '24'}
                                                    height={deviceType.isDesktop ? '32' : '24'}
                                                    viewBox="0 0 40 40"
                                                >
                                                    <ChevronLeft />
                                                </Icon>
                                                <span
                                                    className={dt([
                                                        'text-gray-70',
                                                        'text-normal',
                                                        'font-semibold',
                                                        'lg:text-xlarge',
                                                    ])}
                                                >
                                                    Previous
                                                </span>
                                            </button>
                                        )}
                                    </div>
                                    <div
                                        className={`${cx('changePageNo')} ${dt([
                                            'text-normal',
                                            'text-gray-70',
                                            'font-open-sans-regular',
                                        ])}`}
                                    >
                                        {this.displayPaginationItems(pages, currentPageValue)}
                                    </div>
                                    <div
                                        className={`${cx(
                                            'next',
                                            isRightNavButtonEnbled && 'disabled'
                                        )} ${dt([
                                            'lg:h-8',
                                            'smOnly:ml-1',
                                            'md:ml-3',
                                            isRightNavButtonEnbled && 'opacity-30',
                                        ])}`}
                                        ref={(component) => {
                                            this.nextDiv = component;
                                        }}
                                    >
                                        {!isRightNavButtonEnbled && (
                                            <button
                                                type="button"
                                                onClick={this.pageNoDropdownChange}
                                                data-from="next"
                                                disabled={isRightNavButtonEnbled}
                                            >
                                                <span
                                                    className={dt([
                                                        'text-gray-70',
                                                        'text-normal',
                                                        'font-semibold',
                                                        'lg:text-xlarge',
                                                    ])}
                                                >
                                                    Next
                                                </span>

                                                <Icon
                                                    className={dt(['align-top'])}
                                                    iconType="svg"
                                                    width={deviceType.isDesktop ? '32' : '24'}
                                                    height={deviceType.isDesktop ? '32' : '24'}
                                                    viewBox="0 0 40 40"
                                                >
                                                    <ChevronRight />
                                                </Icon>
                                            </button>
                                        )}
                                    </div>
                                </div>
                            </div>
                        )}
                </div>
                {!hideIsNative && (
                    <div
                        className={dt([
                            'w-full',
                            'mx-auto',
                            'flex',
                            'flex-row',
                            'justify-center',
                            'mt-6',
                            'pb-2',
                            'float-left',
                        ])}
                    >
                        <button
                            type="button"
                            onClick={this.reditectToLinkCard}
                            className={dt([
                                'text-center',
                                'border',
                                'border-solid',
                                'border-gray-15',
                                'px-2',
                                'py-4',
                                'mr-2',
                                'md:mr-4',
                                'w-[157.5px]',
                                'md:w-[227px]',
                                'lg:w-[294px]',
                                'h-[132px]',
                                'cursor-pointer',
                            ])}
                        >
                            <Icon iconType="svg" width="40px" height="40px" viewBox="0 0 40 40">
                                <Rewards />
                            </Icon>
                            <div
                                className={dt([
                                    'text-black',
                                    'font-semibold',
                                    'text-normal',
                                    'pt-2',
                                ])}
                            >
                                Import Points
                            </div>
                            <div className={dt(['text-gray-60', 'text-xsmall'])}>
                                Link a Credit Card
                            </div>
                        </button>
                        <a href={menuFaqRewardsLink}>
                            <div
                                className={dt([
                                    'text-center',
                                    'border',
                                    'border-solid',
                                    'border-gray-15',
                                    'px-2',
                                    'py-4',
                                    'w-[157.5px]',
                                    'md:w-[227px]',
                                    'lg:w-[294px]',
                                    'h-[132px]',
                                    'cursor-pointer',
                                ])}
                            >
                                <Icon iconType="svg" width="40px" height="40px" viewBox="0 0 40 40">
                                    <Alerts />
                                </Icon>
                                <div
                                    className={dt([
                                        'text-black',
                                        'font-semibold',
                                        'text-normal',
                                        'mx-2',
                                        'pt-2',
                                    ])}
                                >
                                    Find Answers
                                </div>
                                <div className={dt(['text-gray-60', 'text-xsmall'])}>
                                    Rewards FAQs
                                </div>
                            </div>
                        </a>
                    </div>
                )}
            </section>
        );
    }
}

export default History;
