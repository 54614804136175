import * as types from '../actionTypes/AccountDetailsActionTypes';

export default function AccountDetailsReducer(
    state = { profile: { isLoading: true } },
    action = {}
) {
    switch (action.type) {
        case types.PROFILE: {
            return {};
        }
        case types.ORDERS:
        case types.GET_ORDER: {
            return { ...state };
        }
        case types.GET_PROFILE_SUCCESS: {
            return { ...state, ...action };
        }
        case types.GET_PROFILE_ERROR: {
            const errorProfile = action.errorResponse;
            return { ...state, errorProfile };
        }

        default:
            return state;
    }
}

AccountDetailsReducer.reducerName = 'accountDetails';
