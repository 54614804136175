export const NEW_CHECK_ACCOUNT = 'NEW_CHECK_ACCOUNT';
export const NEW_CHECK_ACCOUNT_ERROR = 'NEW_CHECK_ACCOUNT_ERROR';
export const NEW_CHECK_ACCOUNT_SUCCESS = 'NEW_CHECK_ACCOUNT_SUCCESS';
export const NEW_ENROLL_REWARD_CHECK = 'NEW_ENROLL_REWARD_CHECK';
export const NEW_ENROLL_REWARD_CHECK_ERROR = 'NEW_ENROLL_REWARD_CHECK_ERROR';
export const NEW_ENROLL_REWARD_CHECK_SUCCESS = 'NEW_ENROLL_REWARD_CHECK_SUCCESS';
export const NEW_ENROLL_REWARD_LINK = 'NEW_ENROLL_REWARD_LINK';
export const NEW_ENROLL_REWARD_LINK_ERROR = 'NEW_ENROLL_REWARD_LINK_ERROR';
export const NEW_ENROLL_REWARD_LINK_SUCCESS = 'NEW_ENROLL_REWARD_LINK_SUCCESS';
export const NEW_SEND_AUTH_EMAIL = 'NEW_SEND_AUTH_EMAIL';
export const NEW_SEND_AUTH_EMAIL_SUCCESS = 'NEW_SEND_AUTH_EMAIL_SUCCESS';
export const NEW_SEND_AUTH_EMAIL_ERROR = 'NEW_SEND_AUTH_EMAIL_ERROR';
export const NEW_LNL_PAGE_LOADED_SUCCESS = 'NEW_LNL_PAGE_LOADED_SUCCESS';
export const NEW_LNL_ACCOUNT_FORM_LOADED_SUCCESS = 'NEW_LNL_ACCOUNT_FORM_LOADED_SUCCESS';
export const NEW_LNL_TRACK_TRANSITION_PAGE_EVENT = 'NEW_LNL_TRACK_TRANSITION_PAGE_EVENT';
export const NEW_LNL_FORM_ERROR_TRACK_EVENT = 'NEW_LNL_FORM_ERROR_TRACK_EVENT';
export const NEW_LNL_FORM_SUCCESS_TRACK_EVENT = 'NEW_LNL_FORM_SUCCESS_TRACK_EVENT';
export const NEW_GET_IRIS_COMPONENT_LIST = 'NEW_GET_IRIS_COMPONENT_LIST';
export const NEW_GET_IRIS_COMPONENT_LIST_SUCCESS = 'NEW_GET_IRIS_COMPONENT_LIST_SUCCESS';
export const NEW_GET_IRIS_COMPONENT_LIST_FAILURE = 'NEW_GET_IRIS_COMPONENT_LIST_FAILURE';
export const CLEAR_NEW_CHECK_ACCOUNT = 'CLEAR_NEW_CHECK_ACCOUNT';
export const LNL_ON_AUTHENTICATION = 'LNL_ON_AUTHENTICATION';
export const CLEAR_NEW_ENROLL_REWARD_LINK_ERROR = 'CLEAR_NEW_ENROLL_REWARD_LINK_ERROR';
export const CLEAR_LNL_DATA = 'CLEAR_LNL_DATA';
