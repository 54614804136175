import SiteComponentReducers from 'yoda-site-components/lib/reducers';
import SignInReducer from 'yoda-site-components/lib/reducers/SigninReducer';
import MissingPointsReducer from 'yoda-account-components/lib/reducers/MissingPointsReducer';
import MyListNameReducer from 'yoda-account-components/lib/reducers/MyListNameReducer';
import RewardsReducer from 'yoda-site-components/lib/reducers/RewardsReducer';
import CreateAccountReducer from 'yoda-site-components/lib/reducers/CreateAccountReducer';
import WebvitalsReducer from 'yoda-site-components/lib/reducers/WebvitalsReducer';

import LaunchNLearnReducer from './LaunchNLearnReducer';
import HistoryReducer from './HistoryReducer';
import LaunchNLearnNewReducer from './LaunchNLearnNewReducer';
import GetAccountReducer from './GetAccountReducer';
import AccountDetailsReducer from './AccountDetailsReducer';
import RegistryReducer from './RegistryReducer';

const rootReducer = {
    historyDetails: HistoryReducer,
    launchNLearnDetails: LaunchNLearnReducer,
    newLaunchNLearnDetails: LaunchNLearnNewReducer,
    getAccountDetails: GetAccountReducer,
    accountDetails: AccountDetailsReducer,
    RegistryReducer,
    ...SiteComponentReducers,
    rewardsDetails: RewardsReducer,
    signInDetails: SignInReducer,
    missingPointsDetails: MissingPointsReducer,
    camNavMyList: MyListNameReducer,
    /**
     * create account
     * yoda-microsite-cart/src/containers/Pages/SignIn/components/CreateAccountButton/CreateAccountButton.jsx
     * yoda-microsite-account/src/App.jsx
     * yoda-microsite-rewards/src/containers/Pages/LaunchNLearn/LaunchNLearnConnect.jsx
     */
    CreateAccount: CreateAccountReducer,
    webvitals: WebvitalsReducer,
};

export default rootReducer;
