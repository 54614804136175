import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames/bind';
import { dt } from 'yoda-core-components/lib/helpers/Utils/GetTailwindToken';
import * as styles from './UserDetails.css';

const cx = classNames.bind(styles);

const UserDetails = (props) => (
    <div
        className={`${cx('userDetails')} ${dt([
            'relative',
            'md:w-[25%]',
            'lg:w-[35%]',
            'sm:text-center',
            'sm:border-r-0',
        ])}`}
    >
        <div
            className={dt([
                'text-right',
                'items-center',
                'flex',
                'sm:justify-center',
                'md:justify-end',
                'smOnly:mb-1',
            ])}
        >
            <div className={dt(['text-large', 'text-black'])}>Welcome,</div>
            <div
                className={dt([
                    'text-large',
                    'text-black',
                    'text-ellipsis',
                    'whitespace-nowrap',
                    'overflow-hidden ',
                ])}
                data-qm-encrypt="true"
            >
                &nbsp;{props.username}
            </div>
        </div>
        <div
            className={dt([
                'md:float-right',
                'text-small',
                'text-gray-70',
                'bg-light-white',
                'inline',
                'font-semibold',
                'px-2',
                'py-1',
                'md:mt-1',
            ])}
        >
            {' '}
            {props.tier} Member
        </div>
    </div>
);

UserDetails.defaultProps = {
    username: '',
};

UserDetails.propTypes = {
    username: PropTypes.string,
    tier: PropTypes.string.isRequired,
};

export default UserDetails;
