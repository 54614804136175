/* Created a duplicate of LaunchNLearnSaga.js as
cleanup of code will be done as part of redesign,
we don't want to mess up existing functionality */
import { takeLatest, put, call, select, all, delay } from 'redux-saga/effects';
import get from 'lodash/get';
import toLower from 'lodash/toLower';
import getBrowserHistory from 'yoda-core-components/lib/navigation/history/getBrowserHistory';
import { getAccountByEmail, enrollReward } from 'yoda-interfaces/lib/Account/AccountApi';
import { putRewardsProfile } from 'yoda-interfaces/lib/Rewards/RewardsApi';
import { getMessageContentSupportingCM } from 'yoda-interfaces/lib/Content/ContentApi';
import FactorySaga from 'yoda-site-components/lib/sagas/FactorySaga';
import LocalStorage from 'yoda-core-components/lib/helpers/LocalStorage/LocalStorage';
import TokenProvider from 'yoda-core-components/lib/helpers/TokenProvider/TokenProvider';
import { isFeatureEnabled } from 'yoda-core-components/lib/helpers/Utils/Utils';
import {
    selectPreferences,
    selectDeviceType,
    selectMessagesTexts,
    selectFeatureFlags,
    selectReqHeaders,
} from 'yoda-site-components/lib/selectors/ContextSelector';
import { getSignInSliderDetails } from 'yoda-site-components/lib/selectors/signinSliderDetailsSelector';
import * as SignInSliderActionTypes from 'yoda-site-components/lib/actionTypes/SignInSliderActionTypes';
import {
    SIGN_IN,
    WEB_CREATE_ACCOUNT_SLIDER_REQUEST,
} from 'yoda-site-components/lib/actionTypes/NativeAppActionTypes';
import { getUserDetails } from 'yoda-site-components/lib/actions/AccountAction';
import {
    NEW_CHECK_ACCOUNT_SUCCESS,
    NEW_CHECK_ACCOUNT_ERROR,
    NEW_SEND_AUTH_EMAIL_SUCCESS,
    NEW_SEND_AUTH_EMAIL_ERROR,
    NEW_ENROLL_REWARD_CHECK_SUCCESS,
    NEW_ENROLL_REWARD_CHECK_ERROR,
    NEW_ENROLL_REWARD_LINK_SUCCESS,
    NEW_ENROLL_REWARD_LINK_ERROR,
    NEW_CHECK_ACCOUNT,
    NEW_ENROLL_REWARD_CHECK,
    NEW_ENROLL_REWARD_LINK,
    NEW_SEND_AUTH_EMAIL,
    NEW_GET_IRIS_COMPONENT_LIST,
    NEW_GET_IRIS_COMPONENT_LIST_FAILURE,
    NEW_GET_IRIS_COMPONENT_LIST_SUCCESS,
    NEW_LNL_ACCOUNT_FORM_LOADED_SUCCESS,
    LNL_ON_AUTHENTICATION,
} from '../actionTypes/LaunchNLearnNewActionTypes';

export function* onAuthentication(action) {
    try {
        const preferences = yield select(selectPreferences);
        const selectedDevice = yield select(selectDeviceType);
        const deviceType = selectedDevice.isDesktop ? 'desktop' : 'mobile';
        const dashboardUrl = get(preferences, `navigateToRewardsDashboard.${deviceType}`);
        const lnlUrl = get(preferences, `navigateToRewardsHome.${deviceType}`);
        const userState = TokenProvider.get('DP_USER_STATE');
        const rewardsStatus = toLower(TokenProvider.get('DP_REWARDS_STATUS') || '');
        const DELAY = get(preferences, 'signInSliderWelcomeMessageTimeOut', 3000);
        const featureFlags = yield select(selectFeatureFlags);
        const reqHeaders = yield select(selectReqHeaders);
        const signInSliderDetails = yield select(getSignInSliderDetails);
        const isWebCreateAccount = get(signInSliderDetails, 'createAccount.isSuccess', false);
        const isNative = yield select((state) => get(state, 'context.isNative', false));
        const enableRewardsWebview = get(featureFlags, 'enableRewardsWebview', true);
        const isNativeRewardsEnabled = isNative && enableRewardsWebview;
        const isNativeCreateAccount = action?.payload?.isCreateAccount && isNativeRewardsEnabled;
        const isCreateAccount = isNative ? isNativeCreateAccount : isWebCreateAccount;

        /** Explictly get userDetails for native, as web uses header to getUserDetails  */
        if (isNativeRewardsEnabled) {
            yield put(getUserDetails());
        }

        // isCreateAccount is handled for slider to show confirmation page and redirect based on that
        if (
            userState !== '0' &&
            rewardsStatus === 'enrolled' &&
            !isCreateAccount &&
            !isNativeRewardsEnabled
        ) {
            const { enableDelayForRewardsRedirection = true } = featureFlags || {};
            const shouldAddDelay = isFeatureEnabled(
                { enableDelayForRewardsRedirection },
                preferences,
                reqHeaders,
                'enableDelayForRewardsRedirection',
                'DP_ENABLE_DELAY_FOR_REWARDS_REDIRECTION',
                'X-ENABLE-DELAY-FOR-REWARDS-REDIRECTION'
            );
            if (shouldAddDelay) {
                yield delay(DELAY);
            }
            yield put({ type: SignInSliderActionTypes.INITIATE_SIGNIN_SLIDER_CLOSE });
            getBrowserHistory().push(dashboardUrl);
        } else if (
            userState !== '0' &&
            (rewardsStatus === 'notenrolled' || rewardsStatus === 'loyaltyenrollmentprogress')
        ) {
            if (isNativeRewardsEnabled) {
                const customizeConfig = {
                    lnlPage: true, // flag to remove Initial errors and prepopulate value in signInSlider
                    lnlEnrollPhoneSlider: true,
                    forceLogin: true,
                    skipOnAuthenticationSuccess: true,
                };
                yield put({
                    type: SignInSliderActionTypes.CUSTOM_STEPUP_SIGNIN_SLIDER,
                    payload: {
                        customizeConfig,
                    },
                });
            }
            yield put({ type: SignInSliderActionTypes.DISPLAY_ENROLL_PHONE });
        } else if (!isCreateAccount && !isNativeRewardsEnabled) {
            yield delay(DELAY);
            yield put({ type: SignInSliderActionTypes.INITIATE_SIGNIN_SLIDER_CLOSE });
            getBrowserHistory().push(lnlUrl);
        }
    } catch (err) {
        /* eslint-disable no-console */
        console.error('LNL saga: ', err);
    }
}

export function* checkAccountSaga(action) {
    const modifyAction = action;
    modifyAction.payload.baseEmail = btoa(modifyAction.payload.emailId);
    try {
        const response = yield call(FactorySaga, getAccountByEmail, action);
        const exists = get(response, 'response.data.exists', false);
        const isSuccess = get(response, 'isSuccess', false);
        const preferences = yield select(selectPreferences);
        const featureFlags = yield select(selectFeatureFlags);
        if (isSuccess) {
            const selectedDevice = yield select(selectDeviceType);
            const deviceType = selectedDevice.isDesktop ? 'desktop' : 'mobile';
            const redirectionUrl = get(preferences, 'navigateToRewardsDashboard');
            const postRedirectUrl = redirectionUrl[deviceType];
            const customizeConfig = {
                openCreateAccount: !exists,
                lnlPage: true, // flag to remove Initial errors and prepopulate value in signInSlider
                lnlCreateAccount: !exists,
                postAuthenticationUrl: postRedirectUrl,
                skipOnAuthenticationSuccess: false, // To control redirection based on rewards status using custom onAuthentication method
            };
            const isNative = yield select((state) => get(state, 'context.isNative', false));
            const enableRewardsWebview = get(featureFlags, 'enableRewardsWebview', true);
            if (isNative && enableRewardsWebview) {
                yield all([
                    put({ type: NEW_CHECK_ACCOUNT_SUCCESS }),
                    put({
                        type: exists ? SIGN_IN : WEB_CREATE_ACCOUNT_SLIDER_REQUEST,
                        payload: {
                            data: { emailAddress: modifyAction?.payload?.emailId },
                            listenerType: LNL_ON_AUTHENTICATION,
                        },
                    }),
                ]);
            } else {
                const prepopulatedForm = {
                    email: get(modifyAction, 'payload.emailId'),
                };
                const { enableADISignin = true } = yield select(selectFeatureFlags) || {};
                // eslint-disable-next-line no-nested-ternary
                const pageName = !exists
                    ? 'create account launch n learn'
                    : enableADISignin
                    ? 'login'
                    : 'sign in launch n learn';
                yield all([
                    put({ type: NEW_CHECK_ACCOUNT_SUCCESS }),
                    put({
                        type: SignInSliderActionTypes.CUSTOM_STEPUP_SIGNIN_SLIDER,
                        payload: {
                            customizeConfig,
                            customCallbacks: {
                                onAuthentication,
                            },
                            prepopulatedForm,
                        },
                    }),
                    put({ type: NEW_LNL_ACCOUNT_FORM_LOADED_SUCCESS, payload: pageName }),
                ]);
            }
        } else {
            const defaultError = get(preferences, 'lnlErrorCodes.DEFAULT');
            yield put({
                type: NEW_CHECK_ACCOUNT_ERROR,
                errorResponse: get(
                    preferences,
                    `lnlErrorCodes[${get(response, 'error.errorCode')}`,
                    defaultError
                ),
            });
        }
    } catch (err) {
        const preferences = yield select(selectPreferences);
        const defaultError = get(preferences, 'lnlErrorCodes.DEFAULT');
        yield put({
            type: NEW_CHECK_ACCOUNT_ERROR,
            errorResponse: get(preferences, `lnlErrorCodes[${get(err, 'errorCode')}`, defaultError),
        });
    }
}

export function* sendAuthorizationEmail(action) {
    try {
        // eslint-disable-next-line no-param-reassign
        action.userAction = 'TRIGGER_AUTHORIZE';
        const response = get(yield call(FactorySaga, putRewardsProfile, action), 'response', {});
        const payload = {
            response: response.data,
        };
        const messagesTexts = yield select(selectMessagesTexts);
        if (response.status === 200) {
            payload.isSuccess = true;
            payload.message = get(
                messagesTexts,
                'rewardsDashboard.authEmailSuccessMessage',
                'Email Sent'
            );
            yield put({ type: NEW_SEND_AUTH_EMAIL_SUCCESS, payload });
        } else {
            payload.isSuccess = false;
            payload.message = get(
                messagesTexts,
                'rewardsDashboard.authEmailFailureMessage',
                'Unable to send email at this moment. Please try again.'
            );
            yield put({ type: NEW_SEND_AUTH_EMAIL_ERROR, payload });
        }
    } catch (error) {
        const messagesTexts = yield select(selectMessagesTexts);
        const payload = {
            isSuccess: false,
            response: error,
            message: get(
                messagesTexts,
                'rewardsDashboard.authEmailFailureMessage',
                'Unable to send email at this moment. Please try again.'
            ),
        };
        yield put({ type: NEW_SEND_AUTH_EMAIL_ERROR, payload });
    }
}

export function* enrollRewardCheckSaga(action) {
    try {
        // eslint-disable-next-line no-param-reassign
        action.userAction = 'SEARCH_AND_LINK';
        const response = get(yield call(FactorySaga, enrollReward, action), 'response', {});
        const payload = {
            response: response.data,
        };
        payload.isLoading = false;
        if (response.status === 200) {
            payload.isSuccess = true;
            LocalStorage.removeData('DP_REWARDS_STATUS');
            yield put({ type: NEW_ENROLL_REWARD_CHECK_SUCCESS, payload });
        } else {
            payload.isSuccess = false;
            yield put({ type: NEW_ENROLL_REWARD_CHECK_ERROR, payload });
        }
    } catch (e) {
        const error = {
            errorMessage: e.message,
            errorCode: 'REWARDS_CHECK_EXCEPTION',
        };
        yield put({
            type: NEW_ENROLL_REWARD_CHECK_ERROR,
            payload: { isSuccess: false, response: error },
        });
    }
}

export function* enrollRewardlinkSaga(action) {
    try {
        // eslint-disable-next-line no-param-reassign
        action.userAction = 'SEARCH_AND_LINK';
        const response = get(yield call(FactorySaga, enrollReward, action), 'response', {});
        const payload = {
            response: response.data,
        };
        payload.isLoading = false;
        if (response.status === 200) {
            // const preferences = yield select(selectPreferences);
            // const selectedDevice = yield select(selectDeviceType);
            // const deviceType = selectedDevice.isDesktop ? 'desktop' : 'mobile';
            // const dashboardUrl = get(preferences, `navigateToRewardsDashboard.${deviceType}`);
            LocalStorage.removeData('DP_REWARDS_STATUS');
            payload.isSuccess = true;
            yield all([
                put({ type: NEW_ENROLL_REWARD_LINK_SUCCESS, payload }),
                put({ type: SignInSliderActionTypes.INITIATE_SIGNIN_SLIDER_CLOSE }),
            ]);
            // yield put({ type: SignInSliderActionTypes.INITIATE_SIGNIN_SLIDER_CLOSE });
            // if (toLower(get(response, 'data.status', '')) === 'enrolled') {
            //     getBrowserHistory().push(dashboardUrl);
            // }
        } else {
            const preferences = yield select(selectPreferences);
            const defaultError = get(preferences, 'lnlErrorCodes.DEFAULT');
            const errorPayload = {
                message: get(
                    preferences,
                    `lnlErrorCodes[${get(response, 'error.errorCode')}`,
                    defaultError
                ),
                isSuccess: false,
            };
            yield put({ type: NEW_ENROLL_REWARD_LINK_ERROR, payload: errorPayload });
        }
    } catch (e) {
        const preferences = yield select(selectPreferences);
        const defaultError = get(preferences, 'lnlErrorCodes.DEFAULT');
        const errorPayload = {
            message: get(preferences, `lnlErrorCodes[${get(e, 'errorCode')}`, defaultError),
            isSuccess: false,
        };
        yield put({ type: NEW_ENROLL_REWARD_LINK_ERROR, payload: errorPayload });
    }
}

function* getIrisComponentList(action) {
    const context = (state) => state.context;
    const appContext = yield select(context);
    const { enableCoreMediaContent = true } = appContext?.featureFlags || {};
    try {
        const modifyAction = action;
        if (enableCoreMediaContent) {
            modifyAction.isCMHeaderRequired = true;
        }
        let components = yield call(getMessageContentSupportingCM, modifyAction, true);
        if (components?.status === 200) {
            yield put({
                type: NEW_GET_IRIS_COMPONENT_LIST_SUCCESS,
                components: components.content || [],
                isCoreMedia: modifyAction.isCMHeaderRequired,
            });
        } else if (components?.status !== 200) {
            modifyAction.isCMHeaderRequired = false;
            components = yield call(getMessageContentSupportingCM, modifyAction);
            yield put({
                type: NEW_GET_IRIS_COMPONENT_LIST_SUCCESS,
                components,
                isCoreMedia: modifyAction.isCMHeaderRequired,
            });
        } else {
            const error = {
                errorCode: 'NO_LNL_COMPONENTS_RECEIVED_FROM_IRIS',
                message: 'No content components returned from IRIS service',
            };
            yield put({ type: NEW_GET_IRIS_COMPONENT_LIST_FAILURE, error });
        }
    } catch (err) {
        const error = {
            errorMessage: err.message,
            errorCode: err.errorCode || 'EXCEPTION_IN_GET_LNL_IRIS_CONTENT_SAGA',
        };
        yield put({ type: NEW_GET_IRIS_COMPONENT_LIST_FAILURE, error });
    }
}

export default function* watchLaunchNLearnSaga() {
    yield takeLatest(NEW_CHECK_ACCOUNT, checkAccountSaga);
    yield takeLatest(LNL_ON_AUTHENTICATION, onAuthentication);
    yield takeLatest(NEW_ENROLL_REWARD_CHECK, enrollRewardCheckSaga);
    yield takeLatest(NEW_ENROLL_REWARD_LINK, enrollRewardlinkSaga);
    yield takeLatest(NEW_SEND_AUTH_EMAIL, sendAuthorizationEmail);
    yield takeLatest(NEW_GET_IRIS_COMPONENT_LIST, getIrisComponentList);
}

watchLaunchNLearnSaga.sagaName = 'newLaunchNLearnSaga';
