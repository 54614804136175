import PropTypes from 'prop-types';
import React from 'react';
import Icon from 'yoda-core-components/lib/components/Icon/Icon';
import TriangleDownDark from 'yoda-core-components/lib/assets/icons/navigation/triangle-down.svg';
import classNames from 'classnames/bind';
import * as styles from './DropDownValidate.css';

const cx = classNames.bind(styles);
function DropDown({ data = [], selected, disabled, className, change, theme, datatlPrivate }) {
    const filter = String(selected);
    const filterOptions =
        data.length > 0
            ? data &&
              data.map((item) => (
                  <option
                      key={`${item.key}_${item.value}`}
                      value={item.key}
                      disabled={disabled === item.key}
                  >
                      {item.value.replace(',', '')}
                  </option>
              ))
            : null;
    return (
        <div className={cx(className)}>
            <span className={cx('selectDropArrow')}>
                <Icon iconType="svg" width="16px" height="16px" viewBox="0 0 16 16">
                    <TriangleDownDark />
                </Icon>
            </span>
            <select
                data-tlprivate={datatlPrivate}
                data-qm-block={datatlPrivate}
                onChange={change}
                value={filter}
                className={theme}
            >
                {filterOptions}
            </select>
        </div>
    );
}

DropDown.propTypes = {
    data: PropTypes.array,
    datatlPrivate: PropTypes.bool,
    selected: PropTypes.string.isRequired,
    disabled: PropTypes.string.isRequired,
    change: PropTypes.func.isRequired,
    className: PropTypes.string,
    theme: PropTypes.string,
};

DropDown.defaultProps = {
    theme: '',
    datatlPrivate: false,
    className: 'selectContent',
    data: [],
};
export default DropDown;
