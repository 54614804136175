import * as types from '../actionTypes/HistoryActionTypes';

export default function HistoryReducer(
    state = {
        showLoader: false,
        rewardsData: { pages: 0 },
        activityData: { pages: 0 },
        missingPointsHistory: {},
    },
    action = {}
) {
    switch (action.type) {
        case types.GET_REWARDS_HISTORY_DETAILS:
        case types.GET_ACTIVITY_HISTORY_DETAILS: {
            const showLoader = true;
            return { ...state, showLoader };
        }

        case types.GET_REWARDS_HISTORY_SUCCESS: {
            const rewardsData = action.rewardsHistory.data;
            const showLoader = false;
            return { ...state, rewardsData, showLoader };
        }

        case types.GET_ACTIVITY_HISTORY_SUCCESS: {
            const activityData = action.activityHistory.data;
            const showLoader = false;
            return { ...state, activityData, showLoader };
        }

        case types.GET_TRANSACTION_DETAILS:
            return state;

        case types.GET_TRANSACTION_DETAILS_SUCCESS: {
            const transactionDetailsData = action.transactionDetails.data;
            return { ...state, transactionDetailsData };
        }

        case types.GET_TRANSACTION_DETAILS_ERROR:
        case types.GET_MISSING_POINTS_HISTORY_ERROR: {
            const { errorResponse } = action;
            return { ...state, errorResponse };
        }

        case types.GET_MISSING_POINTS_HISTORY_SUCCESS: {
            const missingPointsHistory = action.missingPointsHistory.data;
            return { ...state, missingPointsHistory };
        }

        case types.SET_REWARDS_TAB_HISTORY:
            return {
                ...state,
                isRewardsHistoryTabEnabled: action.isMobile
                    ? !state.isRewardsHistoryTabEnabled
                    : true,
                isMissingPointsEnabled: false,
                isActivityHistoryTabEnabled: false,
            };
        case types.SET_MISSING_TAB_HISTORY:
            return {
                ...state,
                isMissingPointsEnabled: action.isMobile ? !state.isMissingPointsEnabled : true,
                isRewardsHistoryTabEnabled: false,
                isActivityHistoryTabEnabled: false,
            };
        case types.SET_DEFAULT_TAB_HISTORY:
            return {
                ...state,
                isActivityHistoryTabEnabled: false,
                isMissingPointsEnabled: false,
                isRewardsHistoryTabEnabled: true,
            };
        case types.SET_ACTIVITY_TAB_HISTORY:
            return {
                ...state,
                isActivityHistoryTabEnabled: action.isMobile
                    ? !state.isActivityHistoryTabEnabled
                    : true,
                isMissingPointsEnabled: false,
                isRewardsHistoryTabEnabled: false,
            };

        default:
            return state;
    }
}

HistoryReducer.reducerName = 'historyDetails';
