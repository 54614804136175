import { fork, all } from 'redux-saga/effects';
import siteComponentSagas from 'yoda-site-components/lib/sagas';
import MissingPointsSaga from 'yoda-account-components/lib/sagas/MissingPointsSaga';
import watchMyListSaga from 'yoda-account-components/lib/sagas/MyListSaga';
import watchTokenSaga from 'yoda-account-components/lib/sagas/TokenSaga';
import watchHistorySaga from './HistorySaga';
import watchNewLaunchNLearnSaga from './LaunchNLearnNewSaga';
import watchGetAccount from './GetAccountSaga';
import watchAccountDetailsSaga from './AccountDetailsSaga';
import watchRegistrySaga from './RegistrySaga';
import watchLaunchNLearnSaga from './LaunchNLearnSaga';

const siteComponentSagaList = Object.values(siteComponentSagas.injectedSagas);
const productSagas = [
    watchNewLaunchNLearnSaga,
    watchHistorySaga,
    watchGetAccount,
    watchAccountDetailsSaga,
    watchTokenSaga,
    watchRegistrySaga,
    MissingPointsSaga,
    watchMyListSaga,
    watchLaunchNLearnSaga,
    ...siteComponentSagaList,
];

const injectedSagas = {};
for (const saga of productSagas) { // eslint-disable-line
    // 1. Check that every saga has a name
    if (!saga.sagaName) {
        throw new Error(
            `Every saga has to have sagaName property. For debug purposes: ${saga.toString()}`
        );
    }

    // 2. Check that we have only unique sagas (one could be from components, another the same one from shared)
    if (injectedSagas[saga.sagaName]) {
        throw new Error(
            `That saga ${saga.sagaName} was already registered. Only 1 instance for particular name is allowed`
        );
    }
    injectedSagas[saga.sagaName] = saga;
}

const productRootSaga = function* productRootSaga() {
    yield all(Object.values(injectedSagas).map((saga) => fork(saga)));
};

productRootSaga.injectedSagas = injectedSagas;

export default productRootSaga;
