/* Created a duplicate of LaunchNLearnReducer.js as
cleanup of code will be done as part of redesign,
we don't want to mess up existing functionality */
import * as types from '../actionTypes/LaunchNLearnNewActionTypes';

export default function LaunchNLearnNewReducer(state = {}, action = {}) {
    switch (action.type) {
        case types.NEW_CHECK_ACCOUNT: {
            return { ...state, accountIdentity: { isLoading: true } };
        }

        case types.NEW_CHECK_ACCOUNT_SUCCESS: {
            return { ...state, accountIdentity: { isSuccess: true, isLoading: false } };
        }

        case types.NEW_CHECK_ACCOUNT_ERROR: {
            return {
                ...state,
                accountIdentity: {
                    isSuccess: false,
                    isLoading: false,
                    error: action.errorResponse,
                },
            };
        }

        case types.CLEAR_NEW_CHECK_ACCOUNT: {
            return { ...state, accountIdentity: {} };
        }
        case types.NEW_ENROLL_REWARD_CHECK: {
            const rewardCheck = {};
            rewardCheck.isLoading = true;
            return { ...state, rewardCheck };
        }

        case types.NEW_ENROLL_REWARD_CHECK_SUCCESS:
        case types.NEW_ENROLL_REWARD_CHECK_ERROR: {
            const rewardCheck = action.payload;
            return { ...state, rewardCheck };
        }
        case types.NEW_ENROLL_REWARD_LINK: {
            const rewardLink = {};
            rewardLink.isLoading = true;
            return { ...state, rewardLink };
        }

        case types.NEW_ENROLL_REWARD_LINK_SUCCESS: {
            const rewardLink = action.payload;
            const rewardCheck = {};
            return { ...state, rewardLink, rewardCheck };
        }

        case types.NEW_ENROLL_REWARD_LINK_ERROR: {
            const rewardLink = action.payload;
            return { ...state, rewardLink };
        }
        case types.CLEAR_NEW_ENROLL_REWARD_LINK_ERROR: {
            return { ...state, rewardLink: {} };
        }
        case types.NEW_SEND_AUTH_EMAIL: {
            return { ...state, authEmail: { isLoading: true } };
        }

        case types.NEW_SEND_AUTH_EMAIL_SUCCESS:
        case types.NEW_SEND_AUTH_EMAIL_ERROR: {
            return { ...state, authEmail: { isLoading: false, ...action.payload } };
        }
        case types.NEW_GET_IRIS_COMPONENT_LIST_SUCCESS:
            return { ...state, irisComponents: action.components, isCoreMedia: action.isCoreMedia };
        case types.NEW_GET_IRIS_COMPONENT_LIST_FAILURE:
            return { ...state, irisComponentError: action.error };
        case types.CLEAR_LNL_DATA:
            return { ...state, authEmail: {}, rewardLink: {}, rewardCheck: {} };
        default:
            return state;
    }
}

LaunchNLearnNewReducer.reducerName = 'newLaunchNLearnDetails';
