/**
 * List of constants required for ensighten tagging, we will be using on page level components to update jcpDl object
 */
const RewardsConfig = {
    WELCOME_PAGE_ID: 'rewards',
    PAGE_NAME: 'dashboard',
    siteSection: {
        HOME: 'rewards',
    },
    printSelection: {
        EVENTNAME: 'openModal',
        NAME: 'rewards dashboard print all rewards - modal',
    },
    missingPoints: {
        pageName: 'rewards missing points history',
    },
    certificateSelection: {
        EVENTNAME: 'openModal',
        EXPIRENAME: 'rewards dashboard print reward expiring soon - modal',
        NONEXPIRENAME: 'rewards dashboard print reward not expiring soon - modal',
    },
};

export default RewardsConfig;
