import universal from 'react-universal-component';
import React from 'react';
import universalImport from 'babel-plugin-universal-import/universalImport';
import Loader from 'yoda-core-components/lib/components/Loader/Loader';
import path from 'path';

export default universal(
    universalImport({
        chunkName: () => 'LazyRewardDashboardChunks',
        path: () => path.join(__dirname, './RewardsLinkCreditCardScreenConnect'),
        resolve: () => require.resolveWeak('./RewardsLinkCreditCardScreenConnect'),
        load: () =>
            Promise.all([
                import(
                    /* webpackChunkName: "LazyRewardDashboardChunks" */ './RewardsLinkCreditCardScreenConnect'
                ), // css name
            ]).then((proms) => proms[0]),
    }),
    {
        loading: () => (
            <div className="loading-wrapper">
                <Loader keepOverlay loaderClass="lazy-loader" />
            </div>
        ),
        error: () => null,
        timeout: 60000,
    }
);
