import React from 'react';
import RewardsHomeScreenLegacy from './components/RewardsHomeScreenLegacy/RewardsHomeScreenLegacyConnect';

/* istanbul ignore next */
const RewardsPreferencesWrapper = (routerProps) => (
    /* eslint-disable react/jsx-props-no-spreading */
    <RewardsHomeScreenLegacy {...routerProps} />
);

export default RewardsPreferencesWrapper;
