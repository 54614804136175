import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as siteAccountAction from 'yoda-site-components/lib/actions/AccountAction';
import { patchHeader } from 'yoda-site-components/lib/actions/NativeAppAction';
import * as rewardsActions from '../../../../../actions/RewardsAction';
import * as rewardsHistoryActions from '../../../../../actions/HistoryAction';
import RewardsHomeScreenLegacy from './RewardsHomeScreenLegacy';

/* istanbul ignore next */
const mapStateToProps = (state) => ({
    deviceType: state.context.deviceType,
    rewardsDetails: state.rewardsDetails.details,
    messagesTexts: state.context.messagesTexts,
    preferences: state.context.preferences,
    featureFlags: state.context.featureFlags,
    missingPointsDetails: state.rewardsDetails.missingPoints,
    mobileRoutes: state.context.preferences.rewardsDashboard.mobileRoutes,
    historyDetails: state.historyDetails.rewardsData,
    isMissingPointsEnabled: state.historyDetails.isMissingPointsEnabled,
    isRewardsHistoryTabEnabled: state.historyDetails.isRewardsHistoryTabEnabled,
    isActivityHistoryTabEnabled: state.historyDetails.isActivityHistoryTabEnabled,
    showLoader: state.historyDetails.showLoader,
    missingPointsHistory: state.historyDetails.missingPointsHistory,
    activityHistoryDetails: state.historyDetails.activityData,
    transactionDetails: state.historyDetails.transactionDetailsData,
    isNative: state.context?.isNative || false,
});
/* istanbul ignore next */
const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(rewardsActions, dispatch),
    sliderActions: bindActionCreators(siteAccountAction, dispatch),
    historyActions: bindActionCreators(rewardsHistoryActions, dispatch),
    patchHeader: bindActionCreators(patchHeader, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(RewardsHomeScreenLegacy);
