export const GET_REWARDS_HISTORY_DETAILS = 'GET_REWARDS_HISTORY_DETAILS';
export const GET_REWARDS_HISTORY_SUCCESS = 'GET_REWARDS_HISTORY_SUCCESS';
export const GET_REWARDS_HISTORY_ERROR = 'GET_REWARDS_HISTORY_ERROR';
export const GET_ACTIVITY_HISTORY_DETAILS = 'GET_ACTIVITY_HISTORY_DETAILS';
export const GET_ACTIVITY_HISTORY_SUCCESS = 'GET_ACTIVITY_HISTORY_SUCCESS';
export const GET_ACTIVITY_HISTORY_ERROR = 'GET_ACTIVITY_HISTORY_ERROR';
export const REWARDS_HISTORY_PAGE_LOADED_SUCCESS = 'REWARDS_HISTORY_PAGE_LOADED_SUCCESS';
export const ACTIVITY_PAGE_LOADED_SUCCESS = 'ACTIVITY_PAGE_LOADED_SUCCESS';
export const HISTORY_FILTER_SELECTION = 'HISTORY_FILTER_SELECTION';
export const HISTORY_PAGINATION = 'HISTORY_PAGINATION';
export const HISTORY_SORT = 'HISTORY_SORT';
export const GET_TRANSACTION_DETAILS = 'GET_TRANSACTION_DETAILS';
export const GET_TRANSACTION_DETAILS_SUCCESS = 'GET_TRANSACTION_DETAILS_SUCCESS';
export const GET_TRANSACTION_DETAILS_ERROR = 'GET_TRANSACTION_DETAILS_ERROR';
export const HISTORY_DETAILS_MODAL = 'HISTORY_DETAILS_MODAL';
export const GET_MISSING_POINTS_DETAILS = 'GET_MISSING_POINTS_DETAILS';
export const GET_MISSING_POINTS_HISTORY_SUCCESS = 'GET_MISSING_POINTS_HISTORY_SUCCESS';
export const GET_MISSING_POINTS_HISTORY_ERROR = 'GET_MISSING_POINTS_HISTORY_ERROR';
export const MISSING_PAGE_LOAD = 'MISSING_PAGE_LOAD';
export const SET_REWARDS_TAB_HISTORY = 'SET_REWARDS_TAB_HISTORY';
export const SET_MISSING_TAB_HISTORY = 'SET_MISSING_TAB_HISTORY';
export const SET_DEFAULT_TAB_HISTORY = 'SET_DEFAULT_TAB_HISTORY';
export const SET_ACTIVITY_TAB_HISTORY = 'SET_ACTIVITY_TAB_HISTORY';
