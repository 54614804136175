import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import get from 'lodash/get';
import RedirectAction from 'yoda-app-shell/lib/action/RedirectAction';
import * as accountDetailsActions from '../../../../../actions/AccountDetailsActions';
import * as actions from '../../../../../actions/RewardsAction';
import RewardsDashboardPageLegacy from './RewardsLegacy';
import { sendAuthEmail } from '../../../../../actions/LaunchNLearnActions';

const mapStateToProps = (state) => ({
    rewardsDetails: state.rewardsDetails.details,
    rewardsSettingsDetails: state.rewardsDetails.rewardsSettingsDetails,
    deviceType: state.context ? state.context.deviceType : {},
    messagesTexts: state.context ? state.context.messagesTexts : {},
    preferences: state.context ? state.context.preferences : {},
    featureFlags: state.context ? state.context.featureFlags : {},
    launchNLearnDetails: state.launchNLearnDetails,
    accountDetails: state.accountDetails,
    userState: get(state.accounts, 'userProfile.userState', ''),
    firstName: get(state.accounts, 'userProfile.firstName', ''),
    isNative: state.context?.isNative || false,
});

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(actions, dispatch),
    sendAuthEmail: bindActionCreators(sendAuthEmail, dispatch),
    accountDetailsActions: bindActionCreators(accountDetailsActions, dispatch),
    redirectAction: bindActionCreators(RedirectAction, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(RewardsDashboardPageLegacy);
