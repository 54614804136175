import PropTypes from 'prop-types';
import React, { Component } from 'react';
import classNames from 'classnames/bind';
import Button from 'yoda-core-components/lib/components/Button';
import { dt } from 'yoda-core-components/lib/helpers/Utils/GetTailwindToken';
import time from 'yoda-core-components/lib/moment/Time';
import Icon from 'yoda-core-components/lib/components/Icon/Icon';
import ChevronDown from 'yoda-core-components/lib/assets/icons/navigation/chevron-down.svg';
import ChevronUp from 'yoda-core-components/lib/assets/icons/navigation/chevron-up.svg';
import * as styles from './History.css';

const cx = classNames.bind(styles);
class HistoryData extends Component {
    static propTypes = {
        data: PropTypes.objectOf.isRequired,
        isDesktop: PropTypes.bool.isRequired,
    };

    static defaultProps = {};

    constructor() {
        super();
        this.state = {
            showHistoryDetails: {},
        };
        this.toggleHistoryDetails = this.toggleHistoryDetails.bind(this);
    }

    toggleHistoryDetails = (value) => {
        this.setState((previousState) => {
            const localState = previousState.showHistoryDetails;
            localState[value] = localState[value] ? !localState[value] : true;
            return {
                showHistoryDetails: localState,
            };
        });
    };

    detailsButtons = (iValue) => (
        <ul>
            {this.state.showHistoryDetails[iValue] ? (
                <li value={iValue}>
                    <Button
                        automationId="at-rewards-details-btn"
                        className={`${cx('rewardsCertDetailsBtn')} ${dt([
                            'text-left',
                            'flex',
                            'items-center',
                        ])}`}
                        buttonType="Link"
                        size="Sm"
                        onClick={() => {
                            this.toggleHistoryDetails(iValue);
                        }}
                    >
                        <div className={dt(['text-left', 'flex', 'items-center', 'text-black'])}>
                            <div>More Details</div>
                            <div className={dt(['pl-1'])}>
                                <Icon iconType="svg" width="20px" height="20px" viewBox="0 0 20 20">
                                    <ChevronUp />
                                </Icon>
                            </div>
                        </div>
                    </Button>
                </li>
            ) : (
                <li value={iValue}>
                    <Button
                        automationId="at-rewards-details-btn"
                        className={`${cx('rewardsCertDetailsBtn')} ${dt([
                            'text-left',
                            'flex',
                            'items-center',
                        ])}`}
                        buttonType="Link"
                        size="Sm"
                        onClick={() => {
                            this.toggleHistoryDetails(iValue);
                        }}
                    >
                        <div className={dt(['text-left', 'flex', 'items-center', 'text-black'])}>
                            <div>More Details</div>
                            <div className={dt(['pl-1'])}>
                                <Icon iconType="svg" width="20px" height="20px" viewBox="0 0 20 20">
                                    <ChevronDown />
                                </Icon>
                            </div>
                        </div>
                    </Button>
                </li>
            )}
        </ul>
    );

    render() {
        const history = [];
        const { data, isDesktop } = this.props;
        const tableCellCommonStyles = dt([
            'border-b',
            'border-solid',
            'border-gray-15',
            'table-cell',
            'py-0',
            'px-2.5',
            'min-w-[auto]',
            'max-w-[250px]',
            'smMd:min-w-[50px]',
            'text-small',
            'text-black',
            'align-middle',
        ]);
        if (data && data.length > 0) {
            for (let i = 0; i < data.length; i += 1) {
                let expiresIn = '';
                const historyData = data[i];
                const { certValue } = historyData;
                const displayCertValue =
                    historyData.percentOff === true ? `${certValue}%` : `$${certValue}`;
                if (historyData.expiresIn || historyData.expiresIn === 0) {
                    expiresIn = historyData.expiresIn.toString();
                }
                const days = expiresIn !== '1' ? 'days' : 'day';
                let daysLeft = expiresIn !== '0' ? `${expiresIn} ${days} left!` : 'Expires today!';
                daysLeft = isDesktop ? (
                    <div
                        className={`${cx('rewardsCertAlert')} ${dt([
                            'text-brand-red',
                            'text-small',
                            'font-open-sans-bold',
                        ])}`}
                    >
                        {daysLeft}
                    </div>
                ) : (
                    <span
                        className={`${cx('rewardsCertAlert')} ${dt([
                            'text-brand-red',
                            'text-small',
                            'font-open-sans-bold',
                        ])}`}
                    >
                        {daysLeft}
                    </span>
                );

                if (historyData.statusDescription === 'Reward Earned') {
                    history.push(
                        <div
                            className={`${cx(
                                'divTableRow',
                                'rewardsEarned',
                                'rewardsHistoryData'
                            )} ${dt(['table-row', 'bg-white', 'h-8'])}`}
                        >
                            <div
                                className={`${cx(
                                    'divTableCell',
                                    'displayLinebreak',
                                    tableCellCommonStyles
                                )} ${dt(['whitespace-pre-line', '!py-3', '!px-4'])}`}
                            >
                                <div className={dt(['font-open-sans', 'pb-1'])}>
                                    {time(historyData.expiryDate).format('MMM DD, YYYY')}
                                </div>
                                {expiresIn && (
                                    <div
                                        className={`${cx('rewardsCertAlert')} ${dt([
                                            'text-brand-red',
                                            'text-small',
                                            'font-open-sans-bold',
                                            'pb-1',
                                        ])}`}
                                    >
                                        {daysLeft}
                                    </div>
                                )}
                                <div>
                                    <div
                                        className={`${cx('rewardsCertDetails')} ${dt([
                                            'font-open-sans-regular',
                                        ])}`}
                                    >
                                        {this.detailsButtons(i)}
                                        {this.state.showHistoryDetails[i] && (
                                            <div>
                                                <div className={dt(['pt-2'])}>
                                                    <b className={dt(['pb-1'])}>Issue Date </b>
                                                    <br />
                                                    {time(historyData.issueDate).format('LL')}
                                                </div>
                                                <div className={dt(['lg:hidden', 'pt-2'])}>
                                                    <b className={dt(['pb-1'])}>Rewards Type </b>
                                                    <br />
                                                    {historyData.typeDescription}
                                                    {historyData.typeDescription !==
                                                    'JCP BONUS BUCK$'
                                                        ? ' Reward'
                                                        : ''}
                                                    1 x {displayCertValue} Reward{' '}
                                                </div>
                                                <div className={dt(['md:hidden', 'pt-2'])}>
                                                    <b className={dt(['pb-1'])}>Status</b>
                                                    <p className={dt(['text-gray-70'])}>
                                                        {displayCertValue} Reward
                                                    </p>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div
                                className={`${cx('divTableCell', tableCellCommonStyles)} ${dt([
                                    'smMd:!hidden',
                                ])}`}
                            >
                                {historyData.typeDescription}
                                {historyData.typeDescription !== 'JCP BONUS BUCK$' ? ' Reward' : ''}
                                1 x {displayCertValue} Reward{' '}
                            </div>
                            <div
                                className={`${cx('divTableCell', tableCellCommonStyles)} ${dt([
                                    'smOnly:!hidden',
                                    '!text-gray-70',
                                ])}`}
                            >
                                <div className={dt(['pb-1'])}> {historyData.statusDescription}</div>
                                <div>{displayCertValue} Reward</div>
                            </div>
                            <div
                                className={`${cx('divTableCell', tableCellCommonStyles)} ${dt([
                                    '!px-4',
                                    'font-open-sans-semibold',
                                    '!text-black',
                                    'last:!bg-light-white',
                                ])}`}
                            >
                                {displayCertValue}
                            </div>
                        </div>
                    );
                } else {
                    history.push(
                        <div
                            className={`${cx('divTableRow', 'rewardsHistoryData')} ${dt([
                                'table-row',
                                'bg-white',
                                'h-8',
                            ])}`}
                        >
                            <div
                                className={`${cx(
                                    'divTableCell',
                                    'displayLinebreak',
                                    tableCellCommonStyles
                                )} ${dt(['whitespace-pre-line', '!py-3', '!px-4'])}`}
                            >
                                <div className={dt(['font-open-sans', 'pb-1'])}>
                                    {time(historyData.expiryDate).format('MMM DD, YYYY')}
                                </div>
                                <div>
                                    <div
                                        className={`${cx('rewardsCertDetails')} ${dt([
                                            'font-open-sans-regular',
                                        ])}`}
                                    >
                                        {this.detailsButtons(i)}
                                        {this.state.showHistoryDetails[i] && (
                                            <div>
                                                <div>
                                                    {historyData.statusDescription ===
                                                    'Reward Redeemed' ? (
                                                        <span>Used on {historyData.usedDate}</span>
                                                    ) : (
                                                        <span />
                                                    )}
                                                </div>
                                                <div className={dt(['pt-2'])}>
                                                    <b className={dt(['pb-1'])}>Issue Date: </b>
                                                    <br />
                                                    {time(historyData.issueDate).format('LL')}
                                                </div>
                                                <div className={dt(['lg:hidden', 'pt-2'])}>
                                                    <b className={dt(['pb-1'])}>Rewards Type </b>
                                                    <br />
                                                    {historyData.typeDescription}
                                                    {historyData.typeDescription !==
                                                    'JCP BONUS BUCK$'
                                                        ? ' Reward'
                                                        : ''}
                                                    1 x {displayCertValue} Reward{' '}
                                                </div>
                                                <div className={dt(['md:hidden', 'pt-2'])}>
                                                    <b className={dt(['pb-1'])}>Status</b>
                                                    <br />
                                                    <p className={dt(['text-gray-70'])}>
                                                        {historyData.statusDescription}
                                                    </p>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div
                                className={`${cx('divTableCell', tableCellCommonStyles)} ${dt([
                                    'smMd:!hidden',
                                    '!text-gray-70',
                                ])}`}
                            >
                                {historyData.typeDescription}
                                {historyData.typeDescription !== 'JCP BONUS BUCK$' ? ' Reward' : ''}
                                1 x {displayCertValue} Reward{' '}
                            </div>
                            <div
                                className={`${cx('divTableCell', tableCellCommonStyles)} ${dt([
                                    'smOnly:!hidden',
                                    '!text-gray-70',
                                ])}`}
                            >
                                <div className={dt(['pb-1'])}> {historyData.statusDescription}</div>
                                <div>
                                    {historyData.statusDescription === 'Reward Redeemed' ? (
                                        <div>{historyData.usedDate}</div>
                                    ) : (
                                        <span />
                                    )}
                                </div>
                            </div>
                            <div
                                className={`${cx('divTableCell', tableCellCommonStyles)} ${dt([
                                    '!px-4',
                                    'last:text-center',
                                    'last:!bg-light-white',
                                    'font-semibold',
                                    '!text-black',
                                ])}`}
                            >
                                {historyData.statusDescription === 'Reward Redeemed' ||
                                historyData.statusDescription === 'Reward Expired'
                                    ? '-'
                                    : ''}
                                {displayCertValue}
                            </div>
                        </div>
                    );
                }
            }
        }
        return <div className={`${cx('divTableBody')} ${dt(['table-row-group'])}`}>{history}</div>;
    }
}

export default HistoryData;
