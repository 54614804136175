import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import classNames from 'classnames/bind';
import Icon from 'yoda-core-components/lib/components/Icon/Icon';
import Success from 'yoda-core-components/lib/assets/svg/Success.svg';
import Close from 'yoda-core-components/lib/assets/svg/Close.svg';
import Button from 'yoda-core-components/lib/components/Button';
import ModalBox from 'yoda-core-components/lib/components/ModalBox/ModalBox';
import { dt } from 'yoda-core-components/lib/helpers/Utils/GetTailwindToken';
import * as styles from './AlertModal.css';

const cx = classNames.bind(styles);

const AlertModal = ({ showModal, closeModal, title, message, hideIcon, iconComponent }) => {
    const modalCloseButtonStyle = dt(['absolute', 'top-0', 'right-0']);
    const modalButtonsStyle = dt(['flex', 'justify-around']);
    const alertModalStyle = dt(['!rounded-lg', 'py-6', 'px-[14px]', 'lg:!w-[440px]']);
    const modalTitleStyle = dt([
        'mb-3',
        '!text-large',
        'font-open-sans-semibold',
        'leading-tight',
        'tracking-[-0.5px]',
        'text-black',
    ]);
    const modalTitleMarginStyle = dt(['mt-[50px]']);
    const modalButtonStyle = dt(['mt-6', 'lg:w-[200px]']);
    const iconStyle = dt(['absolute', 'top-[15%]', '-my-0', 'mx-[41%]']);

    const modalContent = (
        <>
            <button
                type="button"
                aria-label="test"
                onClick={closeModal}
                className={cx('modalCloseButton', modalCloseButtonStyle)}
            >
                <Icon iconType="svg" width="32px" height="32px" viewBox="0 0 32 32">
                    <Close />
                </Icon>
            </button>
            {!hideIcon && (
                <div className={cx('icon', iconStyle)}>
                    <Icon
                        automationId={`${title}_icon`}
                        iconType="svg"
                        width="48px"
                        height="48px"
                        viewBox="0 0 48 48"
                    >
                        {iconComponent}
                    </Icon>
                </div>
            )}
            <div className={cx('modalButtons', modalButtonsStyle)}>
                <Button
                    className={cx('modalButton', modalButtonStyle, 'modalSecondaryButton')}
                    size="Md"
                    automationId={`${title}_modal_button`}
                    buttonType="Secondary"
                    onClick={closeModal}
                >
                    OK
                </Button>
            </div>
        </>
    );
    return (
        <ModalBox
            defaultCloseBtn={false}
            automationId={`${title}_alert_modalbox`}
            showModal={showModal}
            title={title}
            subTitle={message}
            onClose={closeModal}
            modalContentTheme={`${cx('modelContent')} ${dt(['relative'])}`}
            modalBlockTheme={cx('modalSection')}
            modalTheme={cx('alertModal', alertModalStyle, !hideIcon ? 'modalHeight' : '')}
            modalTitleTheme={cx(
                'modalTitle',
                modalTitleStyle,
                !hideIcon ? modalTitleMarginStyle : ''
            )}
            titleAutomationId="Welcome Msg"
            wrapperAutomationId="rewards-welcome"
            defaultHeader
        >
            {modalContent}
        </ModalBox>
    );
};

AlertModal.defaultProps = {
    iconComponent: <Success />,
    showModal: false,
    title: '',
    message: '',
    hideIcon: false,
};
AlertModal.propTypes = {
    showModal: PropTypes.bool,
    title: PropTypes.string,
    message: PropTypes.string,
    closeModal: PropTypes.func.isRequired,
    hideIcon: PropTypes.bool,
    iconComponent: PropTypes.any,
};

export default AlertModal;
