import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames/bind';
import { dt } from 'yoda-core-components/lib/helpers/Utils/GetTailwindToken';
import DropDown from '../DropDown/DropDown';
import * as styles from './History.css';

const cx = classNames.bind(styles);

const HistoryHeader = ({
    rewardsHistoryClass,
    activityHistoryClass,
    missingPointsClass,
    isMissingTabEnabled,
    isMissingPointsEnabled,
    recentFilterValue,
    filterDates,
    historyTabClicked,
    filterOptionChange,
    rewardsTitleData,
}) => (
    <div
        className={`${cx('rewardsTabContent')} ${dt([
            'flex',
            'items-end',
            'sm:flex-wrap',
            'md:justify-between',
            'w-full',
            'border-b',
            'border-solid',
            'border-gray-15',
            'mb-4',
            'smOnly:border-b-0',
            'smOnly:mb-0',
            'smMd:!border-none',
        ])}`}
        data-automation-id="history_header"
    >
        <div
            className={`${cx('rewardsTabs')} ${dt([
                'lg:table-cell',
                'lg:w-[calc(100%-200px)]',
                'smOnly:flex',
                'smMd:w-full',
                'smMd:!hidden',
            ])}`}
        >
            <ul
                className={dt([
                    'm-0',
                    'p-0',
                    'inline-block',
                    'list-none',
                    'smOnly:flex',
                    'flex-col',
                    'w-full',
                ])}
            >
                <li
                    value="1"
                    className={cx(rewardsHistoryClass)}
                    data-automation-id="rewards_history"
                >
                    <button
                        type="button"
                        onClick={() => {
                            historyTabClicked('rewards');
                        }}
                    >
                        Rewards History
                    </button>
                </li>
                <li
                    value="2"
                    className={cx(activityHistoryClass)}
                    data-automation-id="activity_history"
                >
                    <button
                        type="button"
                        onClick={() => {
                            historyTabClicked('activity');
                        }}
                    >
                        Activity History
                    </button>
                </li>
                {isMissingTabEnabled && (
                    <li
                        value="3"
                        className={cx(missingPointsClass)}
                        data-automation-id="missing_points_history"
                    >
                        <button
                            type="button"
                            onClick={() => {
                                historyTabClicked('missing');
                            }}
                        >
                            Missing Points History
                        </button>
                    </li>
                )}
            </ul>
        </div>
        <div
            className={`${cx('rewardsTitleView')} ${dt([
                'lg:!hidden',
                'md:w-[240px]',
                'smOnly:w-full',
                'float-left',
                'relative',
                'smOnly:float-left',
                'smOnly:mx-0',
                'smOnly:mb-4',
            ])}`}
        >
            <DropDown
                data={rewardsTitleData}
                change={(e) => historyTabClicked(e.target.value)}
                selected={activityHistoryClass || missingPointsClass || rewardsHistoryClass}
                datalPrivate
            />
        </div>
        <div
            className={`${cx('rewardsDataView')} ${dt([
                'lg:table-cell',
                'w-[200px]',
                'md:h-[53px]',
                'smOnly:w-full',
                'relative',
                'smOnly:float-left',
                'smOnly:mx-0',
                'lg:mb-4',
            ])}`}
        >
            {!isMissingPointsEnabled && (
                <DropDown
                    data={filterDates}
                    change={filterOptionChange}
                    selected={recentFilterValue}
                    datalPrivate
                />
            )}
        </div>
    </div>
);

HistoryHeader.propTypes = {
    isMissingPointsEnabled: PropTypes.bool.isRequired,
    isMissingTabEnabled: PropTypes.bool.isRequired,
    filterDates: PropTypes.arrayOf(
        PropTypes.shape({
            key: PropTypes.string,
            value: PropTypes.string,
        })
    ).isRequired,
    rewardsTitleData: PropTypes.arrayOf(
        PropTypes.shape({
            key: PropTypes.string,
            value: PropTypes.string,
        })
    ).isRequired,
    historyTabClicked: PropTypes.func.isRequired,
    rewardsHistoryClass: PropTypes.string,
    activityHistoryClass: PropTypes.string,
    missingPointsClass: PropTypes.string,
    recentFilterValue: PropTypes.string,
    filterOptionChange: PropTypes.func.isRequired,
};

HistoryHeader.defaultProps = {
    rewardsHistoryClass: '',
    activityHistoryClass: '',
    missingPointsClass: '',
    recentFilterValue: '',
};

export default HistoryHeader;
