import { takeLatest, put, call } from 'redux-saga/effects';
import get from 'lodash/get';
import getBrowserHistory from 'yoda-core-components/lib/navigation/history/getBrowserHistory';
import TokenProvider from 'yoda-core-components/lib/helpers/TokenProvider/TokenProvider';
import { DP_REWARDS_STATUS } from 'yoda-site-components/lib/common/Constants';
import { getAccountProfile } from 'yoda-interfaces/lib/Account/AccountApi';
import FactorySaga from 'yoda-site-components/lib/sagas/FactorySaga';
import { GET_PROFILE_SUCCESS, PROFILE } from '../actionTypes/AccountDetailsActionTypes';
import { GET_REWARDS_REQUEST, GET_PROFILE_ERROR } from '../actionTypes/RewardsActionTypes';
import { navigateToRewardsHome } from '../common/Constants';

export function* profileSaga(action) {
    try {
        const profileDetails = get(
            yield call(FactorySaga, getAccountProfile, action),
            'response',
            {}
        );
        let profile = {};

        if (profileDetails.data) {
            profile = profileDetails.data;
            profile.isLoading = false;
            if (profile.rewardsProfile && profile.rewardsProfile.status) {
                TokenProvider.set(DP_REWARDS_STATUS, profile.rewardsProfile.status);
            }
        }
        yield put({ type: GET_PROFILE_SUCCESS, profile });

        if (profileDetails.data.rewardsProfile.status === 'Enrolled') {
            yield put({ type: GET_REWARDS_REQUEST, payload: action.payload });
        } else if (
            action.payload.redirect &&
            profileDetails.data.rewardsProfile.status !== 'Pending'
        ) {
            getBrowserHistory().push(navigateToRewardsHome);
        }
    } catch (error) {
        yield put({
            type: GET_PROFILE_ERROR,
            errorResponse: error,
        });
    }
}

export default function* watchAccountDetailsSaga() {
    yield takeLatest(PROFILE, profileSaga);
}
watchAccountDetailsSaga.sagaName = 'accountDetailsSaga';
