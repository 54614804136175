import { takeLatest, put, call } from 'redux-saga/effects';
import get from 'lodash/get';
import { putRewardsProfile } from 'yoda-interfaces/lib/Rewards/RewardsApi';
import FactorySaga from 'yoda-site-components/lib/sagas/FactorySaga';

import {
    SEND_AUTH_EMAIL_SUCCESS,
    SEND_AUTH_EMAIL_ERROR,
    SEND_AUTH_EMAIL,
} from '../actionTypes/LaunchNLearnActionTypes';

export function* sendAuthorizationEmail(action) {
    try {
        // eslint-disable-next-line no-param-reassign
        action.userAction = 'TRIGGER_AUTHORIZE';
        const response = get(yield call(FactorySaga, putRewardsProfile, action), 'response', {});
        const payload = {
            response: response.data,
        };
        if (response.status === 200) {
            payload.isSuccess = true;
            yield put({ type: SEND_AUTH_EMAIL_SUCCESS, payload });
        } else {
            payload.isSuccess = false;
            yield put({ type: SEND_AUTH_EMAIL_ERROR, payload });
        }
    } catch (error) {
        yield put({ type: SEND_AUTH_EMAIL_ERROR, payload: { isSuccess: false, response: error } });
    }
}

export default function* watchLaunchNLearnSaga() {
    yield takeLatest(SEND_AUTH_EMAIL, sendAuthorizationEmail);
}

watchLaunchNLearnSaga.sagaName = 'launchNLearnSaga';
