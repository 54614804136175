import {
    GET_REWARDS_HISTORY_DETAILS,
    GET_ACTIVITY_HISTORY_DETAILS,
    REWARDS_HISTORY_PAGE_LOADED_SUCCESS,
    ACTIVITY_PAGE_LOADED_SUCCESS,
    GET_MISSING_POINTS_DETAILS,
    HISTORY_FILTER_SELECTION,
    HISTORY_PAGINATION,
    HISTORY_SORT,
    HISTORY_DETAILS_MODAL,
    GET_TRANSACTION_DETAILS,
    MISSING_PAGE_LOAD,
    SET_REWARDS_TAB_HISTORY,
    SET_MISSING_TAB_HISTORY,
    SET_DEFAULT_TAB_HISTORY,
    SET_ACTIVITY_TAB_HISTORY,
} from '../actionTypes/HistoryActionTypes';

export const getRewardsHistory = (page, limit, filter, date = 'descend') => ({
    type: GET_REWARDS_HISTORY_DETAILS,
    payload: { page, limit, filter, date },
});

export const getActivityHistory = (page, limit, filter, date = 'descend') => ({
    type: GET_ACTIVITY_HISTORY_DETAILS,
    payload: { page, limit, filter, date },
});

export const getMissingPointsHistory = (page, limit) => ({
    type: GET_MISSING_POINTS_DETAILS,
    payload: { page, limit },
});

export const getRewardsHistoryPL = (pageName) => ({
    type: REWARDS_HISTORY_PAGE_LOADED_SUCCESS,
    payload: pageName,
});

export const getActivityHistoryPL = (pageName) => ({
    type: ACTIVITY_PAGE_LOADED_SUCCESS,
    payload: { pageName },
});

export const getHistoryFilterData = (analyticsData) => ({
    type: HISTORY_FILTER_SELECTION,
    payload: analyticsData,
});

export const getHistoryPaginationData = (data) => ({
    type: HISTORY_PAGINATION,
    payload: data,
});

export const getHistorySortByDate = (data) => ({
    type: HISTORY_SORT,
    payload: data,
});

export const transactionDetailsModal = (pageName) => ({
    type: HISTORY_DETAILS_MODAL,
    payload: pageName,
});

export const getTransactionDetails = (id) => ({
    type: GET_TRANSACTION_DETAILS,
    payload: { id },
});

export const missingPointsLoadAction = () => ({
    type: MISSING_PAGE_LOAD,
});

export const setRewardsTabHistory = (isMobile) => ({
    type: SET_REWARDS_TAB_HISTORY,
    isMobile,
});

export const setMissingTabHistory = (isMobile) => ({
    type: SET_MISSING_TAB_HISTORY,
    isMobile,
});

export const setDefaultTabHistory = (isMobile) => ({
    type: SET_DEFAULT_TAB_HISTORY,
    isMobile,
});

export const setActivityTabHistory = (isMobile) => ({
    type: SET_ACTIVITY_TAB_HISTORY,
    isMobile,
});
