export const scrollToIdBenefits = 'comp_imagebanner_1';
export const navigateToRewardsHome = '/rewards';
export const lilErrorCode = 'REWARDS_ENROLL_LOYALTY_SERVICE_FAIL';
export const sessionError = 'SRV_SESSION_INVALID';
export const internalError = 'SRV_GENERIC_ERROR';
export const termsAndConditionsLink = '/m/rewards-terms-and-conditions?pageId=pg40067700009';
export const benefitsRewardsLink = '/m/rewards-benefits?pageId=pg40067700010';
export const menuFaqRewardsLink = '/m/rewards-faqs?pageId=pg40067700006';
export const tacsRewardsLink = '/m/rewards-terms-and-conditions?pageId=pg40067700009';
export const masterCardHistory = 'Monthly Mastercard Points';
export const mobileTitle = [
    {
        value: 'Rewards History',
        key: 'rewards',
    },
    {
        value: 'Activity History',
        key: 'activity',
    },
    {
        value: 'Missing Points History',
        key: 'missing',
    },
];
export const bonusIconConstants = {
    'Bonus Point Events': '/static-rewards/assets/images/icon-bonus.svg',
    'Perk (Bras)': '/static-rewards/assets/images/icon-bra.svg',
    'Bonus Rewards': '/static-rewards/assets/images/icon-reward.svg',
    'Perk (Shoes)': '/static-rewards/assets/images/icon-shoe.svg',
};
