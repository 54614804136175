import BaseActionFilter from 'yoda-core-components/lib/helpers/Analytics/BaseActionFilter';
import Cookies from 'yoda-core-components/lib/helpers/Cookies/Cookies';
import { ACCOUNT_ID } from 'yoda-site-components/lib/common/Constants';
import TokenProvider from 'yoda-core-components/lib/helpers/TokenProvider/TokenProvider';

class NavPanelActionFilter extends BaseActionFilter {
    getNavPanelPL(store, data) {
        const { context } = store.getState();

        let deviceType = '';
        if (context.deviceType.isMobile) {
            deviceType = 'mobile';
        } else if (context.deviceType.isDesktop) {
            deviceType = 'desktop';
        } else {
            deviceType = 'tablet';
        }

        const navPanelPageDL = this.updateCommonDigitalData();
        /* istanbul ignore next */
        const associateFlag = navPanelPageDL.user.profile.profileInfo.associateFlag || 'no';
        navPanelPageDL.page.pageInfo.pageName = data.pageName;
        navPanelPageDL.page.pageInfo.pageType = data.pageType;
        navPanelPageDL.page.pageInfo.siteSection = data.siteSection;
        navPanelPageDL.page.pageInfo.deviceType = deviceType;
        navPanelPageDL.page.pageInfo.searchRedirect = '';
        navPanelPageDL.page.pageInfo.releaseVersion = context.__RELEASEVERSION__;
        navPanelPageDL.page.pageInfo.version = 'apollo 1 2017 redesign yoda account'; // TODO when we have multiple template
        navPanelPageDL.page.pageInfo.clusterInstance = Cookies.load('DPInstance');
        navPanelPageDL.page.category = {};
        navPanelPageDL.page.category.primaryCategory = 'account';
        navPanelPageDL.page.category.subCategory1 = '';
        navPanelPageDL.page.category.subCategory2 = '';
        navPanelPageDL.page.category.department = 'account';
        navPanelPageDL.user.profile.profileInfo.profileID = TokenProvider.get(ACCOUNT_ID);
        let rewardsFlag = 'no';
        /* istanbul ignore next */
        const rewardTier =
            TokenProvider.get('DP_REWARDS_TIER') ||
            navPanelPageDL.user.profile.profileInfo.rewardsMemberType;
        /* istanbul ignore next */
        if (rewardTier && rewardTier !== 'no rewards account') {
            rewardsFlag = 'yes';
        }
        navPanelPageDL.user.profile.profileInfo.rewardsMemberType = rewardTier; // silver gold platinum bronze red pending
        navPanelPageDL.user.profile.profileInfo.rewardsFlag = rewardsFlag;
        navPanelPageDL.user.profile.profileInfo.associateFlag = associateFlag; // yes or no
        navPanelPageDL.error = [
            {
                errorID: '',
                errorDescription: '',
            },
        ];

        return navPanelPageDL;
    }
}

export default NavPanelActionFilter;
