import { takeLatest, put, call } from 'redux-saga/effects';
import get from 'lodash/get';
import { getAccount } from 'yoda-interfaces/lib/Account/AccountApi';
import FactorySaga from 'yoda-site-components/lib/sagas/FactorySaga';
import TokenProvider from 'yoda-core-components/lib/helpers/TokenProvider/TokenProvider';
import {
    GET_ACCOUNT_SUCCESS,
    GET_ACCOUNT_ERROR,
    GET_ACCOUNT,
} from '../actionTypes/GetAccountTypes';

export function* getAccountSaga(action) {
    const response = get(yield call(FactorySaga, getAccount, action), 'response', {});
    const payload = {
        response: response.data,
    };
    payload.isLoading = false;
    if (response.status === 200) {
        payload.isSuccess = true;
        payload.check = true;

        if (
            payload.response &&
            payload.response.rewardsProfile &&
            payload.response.rewardsProfile.status
        ) {
            TokenProvider.set('DP_REWARDS_STATUS', payload.response.rewardsProfile.status);
        }
        yield put({ type: GET_ACCOUNT_SUCCESS, payload });
    } else {
        payload.userState = '';
        payload.isSuccess = false;
        if (!payload.response) {
            payload.response = {
                errorMessage: 'API error',
            };
        }
        yield put({ type: GET_ACCOUNT_ERROR, payload });
    }
}

export default function* watchGetAccountSaga() {
    yield takeLatest(GET_ACCOUNT, getAccountSaga);
}

watchGetAccountSaga.sagaName = 'getAccountSaga';
