import * as types from 'yoda-site-components/lib/actionTypes/SigninActionTypes';

export default function RegistryReducer(state = {}, action = {}) {
    switch (action.type) {
        case types.GET_REGISTRY_REQUEST_SUCCESS:
        case types.GET_REGISTRY_REQUEST_ERROR: {
            const registryDetails = action.registryData;
            return { ...state, registryDetails };
        }

        default:
            return state;
    }
}
RegistryReducer.reducerName = 'RegistryReducer';
