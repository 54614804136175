import analytics from 'yoda-core-components/lib/helpers/Analytics/Analytics';
import {
    TRACK_SYF_APPLICATION_APPROVED,
    TRACK_SYF_APPLICATION_DECLINED,
    TRACK_SYF_APPLICATION_APPROVED_ADDED_WALLET,
    TRACK_SYF_APPLICATION_ADDED_WALLET,
    TRACK_SYF_VANITY_PAGE_LOAD,
} from 'yoda-site-components/lib/actionTypes/ApplySyfActionTypes';
import { logwebvitals } from 'yoda-site-components/lib/middlewares/WebvitalsMiddleware';
import * as RewardsLoadedActionTypes from '../actionTypes/RewardsActionTypes';
import * as HistoryLoadedActionTypes from '../actionTypes/HistoryActionTypes';
import * as LaunchNLearnNewActionTypes from '../actionTypes/LaunchNLearnNewActionTypes';
import RewardsActionFilter from '../analytics/RewardsActionFilter';
import HistoryActionFilter from '../analytics/HistoryActionFilter';
import LaunchNLearnActionFilter from '../analytics/LaunchNLearnActionFilter';
import NavPanelActionFilter from '../analytics/NavPanelActionFilter';

const rewardsActionFilter = new RewardsActionFilter();
const historyActionFilter = new HistoryActionFilter();
const navPanelActionFilter = new NavPanelActionFilter();
const launchNLearnActionFilter = new LaunchNLearnActionFilter();
/**
 * This function will used only for redux middleware only to track tagging based on action request
 */
/* istanbul ignore next */
const AccountMiddleWare = store => next => action => { // eslint-disable-line
    if (!__SERVER__) {
        let digitalData = null;
        let analyticsEventName = null;

        /**
         * component can trigger an action only for tagging
         * action should contain analytics object as mandatory
         * @param analytics.type - {string}  - tagging key
         * @param analytics.JCPDL - {Object}  - required additional details for tagging event
         */
        try {
            switch (action.type) {
                case RewardsLoadedActionTypes.REWARDS_PAGE_LOADED_SUCCESS:
                    // console.log('********* CkMiddleware REWARDS_PAGE_LOADED_SUCCESS ***********');
                    digitalData = rewardsActionFilter.getRewardsPageDL(store);
                    digitalData = rewardsActionFilter.updateCommonDigitalData(digitalData);
                    analyticsEventName = 'digitalDataReady';
                    logwebvitals(store, digitalData);
                    break;
                case RewardsLoadedActionTypes.REWARDS_CERTIFICATE_SELECTION:
                    // console.log('********* CkMiddleware REWARDS_CERTIFICATE_SELECTION ***********');
                    digitalData = rewardsActionFilter.getCertificateSelectionData(
                        store,
                        action.payload.isExpire
                    );
                    // if (!action.payload.isExpire) {
                    //     digitalData = rewardsActionFilter.updateCommonDigitalData(digitalData);
                    // }
                    analyticsEventName = 'openModal';
                    break;
                case RewardsLoadedActionTypes.NAV_PAGE_SELECETION:
                    // console.log('********* CkMiddleware NAV_PAGE_SELECETION ***********');
                    digitalData = navPanelActionFilter.getNavPanelPL(store, action.payload);
                    digitalData = navPanelActionFilter.updateCommonDigitalData(digitalData);
                    analyticsEventName = 'digitalDataReady';
                    break;
                case RewardsLoadedActionTypes.REWARDS_PROFILE_UPDATE_SUCCESS:
                    // console.log('********* CkMiddleware REWARDS_PROFILE_UPDATE_SUCCESS ***********');
                    digitalData = rewardsActionFilter.getMigrationPL(store, action.payload);
                    digitalData = rewardsActionFilter.updateCommonDigitalData(digitalData);
                    analyticsEventName = action.payload.trackEvent;
                    break;
                case RewardsLoadedActionTypes.REWARDS_DATA_TRACKER:
                    // console.log('********* CkMiddleware REWARDS_PROFILE_UPDATE_SUCCESS ***********');
                    digitalData = {};
                    // digitalData = rewardsActionFilter.updateCommonDigitalData(digitalData);
                    analyticsEventName = action.payload.trackEvent;
                    break;
                case HistoryLoadedActionTypes.REWARDS_HISTORY_PAGE_LOADED_SUCCESS:
                    // console.log('********* CkMiddleware REWARDS_HISTORY_PAGE_LOADED_SUCCESS ***********');
                    digitalData = historyActionFilter.getHistoryPL(store, action.payload);
                    digitalData = historyActionFilter.updateCommonDigitalData(digitalData);
                    analyticsEventName = 'digitalDataReady';
                    break;
                case HistoryLoadedActionTypes.ACTIVITY_PAGE_LOADED_SUCCESS:
                    // console.log('********* CkMiddleware ACTIVITY_PAGE_LOADED_SUCCESS ***********');
                    digitalData = historyActionFilter.getActivityHistoryPL(store);
                    digitalData = historyActionFilter.updateCommonDigitalData(digitalData);
                    analyticsEventName = 'digitalDataReady';
                    break;
                case HistoryLoadedActionTypes.HISTORY_FILTER_SELECTION:
                    // console.log('********* CkMiddleware HISTORY_FILTER_SELECTION ***********');
                    digitalData = historyActionFilter.getHistoryFilterData(store, action.payload);
                    // digitalData = historyActionFilter.updateCommonDigitalData(digitalData);
                    analyticsEventName = 'productFiltersApplied';
                    break;
                case HistoryLoadedActionTypes.HISTORY_PAGINATION:
                    // console.log('********* CkMiddleware HISTORY_PAGINATION ***********');
                    digitalData = historyActionFilter.getHistoryPaginationData(
                        store,
                        action.payload
                    );
                    digitalData = historyActionFilter.updateCommonDigitalData(digitalData);
                    analyticsEventName = 'pageRefresh';
                    break;
                case HistoryLoadedActionTypes.HISTORY_SORT:
                    // console.log('********* CkMiddleware HISTORY_SORT ***********');
                    digitalData = historyActionFilter.getSortByDate(action.payload);
                    analyticsEventName = 'productFiltersApplied';
                    break;
                case HistoryLoadedActionTypes.HISTORY_DETAILS_MODAL:
                    // console.log('********* CkMiddleware HISTORY_DETAILS_MODAL ***********');
                    digitalData = historyActionFilter.getRewardsTransactionOpenModalPL(
                        action.payload
                    );
                    analyticsEventName = 'openModal';
                    break;
                case LaunchNLearnNewActionTypes.NEW_LNL_PAGE_LOADED_SUCCESS:
                    // console.log('********* CkMiddleware LNL_PAGE_LOADED_SUCCESS ***********');
                    digitalData = launchNLearnActionFilter.getLaunchNLearnLoadPL(store);
                    digitalData = launchNLearnActionFilter.updateCommonDigitalData(digitalData);
                    analyticsEventName = 'digitalDataReady';
                    logwebvitals(store, digitalData);
                    break;
                case LaunchNLearnNewActionTypes.NEW_LNL_ACCOUNT_FORM_LOADED_SUCCESS:
                    // console.log('********* CkMiddleware LNL_ACCOUNT_FORM_LOADED_SUCCESS ***********');
                    digitalData = launchNLearnActionFilter.getLnLAccountFormLoadPL(
                        store,
                        action.payload
                    );
                    digitalData = launchNLearnActionFilter.updateCommonDigitalData(digitalData);
                    analyticsEventName = 'digitalDataReady';
                    break;
                case LaunchNLearnNewActionTypes.NEW_LNL_FORM_ERROR_TRACK_EVENT:
                case LaunchNLearnNewActionTypes.NEW_ENROLL_REWARD_LINK_ERROR:
                    // console.log('********* CkMiddleware LNL_FORM_ERROR_TRACK_EVENT ***********');
                    digitalData = launchNLearnActionFilter.getLaunchNLearnFormErrorPL(
                        action.payload
                    );
                    analyticsEventName = 'formError';
                    break;
                case HistoryLoadedActionTypes.MISSING_PAGE_LOAD:
                    // console.log('********* CkMiddleware ACCOUNT_REGISTERED ***********');
                    digitalData = historyActionFilter.getMissingPointsDL(store);
                    analyticsEventName = 'digitalDataReady';
                    break;
                case RewardsLoadedActionTypes.TRACK_OPEN_MISSING_MODAL:
                    digitalData = rewardsActionFilter.getMissingOpenModal();
                    analyticsEventName = 'openModal';
                    break;
                case TRACK_SYF_APPLICATION_APPROVED:
                    digitalData = {};
                    analyticsEventName = 'plccApplicationApproved';
                    break;
                case TRACK_SYF_APPLICATION_DECLINED:
                    digitalData = {};
                    analyticsEventName = 'plccCardApplicationDeclined';
                    break;
                case TRACK_SYF_APPLICATION_ADDED_WALLET:
                    digitalData = {};
                    analyticsEventName = 'plccAddedToWallet';
                    break;
                case TRACK_SYF_APPLICATION_APPROVED_ADDED_WALLET:
                    digitalData = {};
                    analyticsEventName = 'plccApplicationApprovedWalletAdded';
                    break;
                case TRACK_SYF_VANITY_PAGE_LOAD:
                    digitalData = {};
                    analyticsEventName = 'digitalDataReady';
                    break;
                default:
                    break;
            }
            if (analyticsEventName || digitalData) {
                /* eslint-disable no-console */
                console.info('digitalData -', digitalData);
                // console.log('********** Just before firing TRACK **********');
                analytics.track(analyticsEventName, digitalData);
            }
        } catch (error) {
            /* eslint-disable no-console */
            console.log(error);
        }
    }
    if (action.type) {
        return next(action);
    }
};
export default AccountMiddleWare;
