import hostPath from 'yoda-account-components/lib/common/checkHost';

/**
 * Has the page been accessed as a desktop page or a mobile page?
 * @returns {boolean}
 */
export const isDesktopPage = () => {
    /* istanbul ignore if */
    if (__SERVER__) return false;

    const path = window.location.pathname;

    return path.indexOf('cam') !== -1;
};

/**
 * When testing on localhost, the URL '/rewards/dashboard' is used.
 * When on production, the URL '/rewards/rewards/dashboard' is used instead.
 * @returns {boolean}
 */
export const isProductionPage = () => {
    /* istanbul ignore if */
    if (__SERVER__) return false;

    const path = window.location.pathname;

    return path.indexOf('rewards/rewards') !== -1;
};

/**
 * Given a set of rewardsDetails data, return an array of certificates and a boolean.
 * @param {Object} data -
 *    @property {Array} certificates
 *    @property {number} points
 * @returns {Object} -
 *    @property {Array} certificatesData
 *    @property {bool} rewardsPointsExists
 */
export const getCertificatesData = (data) => {
    let certificatesData = [];
    let rewardsPointsExists = false;
    /* istanbul ignore if */
    if (data && data.certificates) {
        certificatesData = data.certificates.data;

        if (Array.isArray(certificatesData)) {
            rewardsPointsExists = !!certificatesData.length;
        }
    }

    return { certificatesData, rewardsPointsExists };
};

export const getImagePath = (isDesktop) => {
    const deviceType = isDesktop ? 'desktop' : 'mobile';
    return hostPath(undefined, deviceType).imagePath;
};

export const range = (start, end) => {
    const length = end - start + 1;
    const rangeReturn = [];

    for (let i = start; i < start + length; i += 1) {
        rangeReturn.push(i);
    }

    return rangeReturn;
};

export const getPageItems = (totalPages, selectedPageNumber) => {
    const boundaryCount = 1;
    const siblingCount = 1;
    const startPages = range(1, Math.min(boundaryCount, totalPages));
    const endPages = range(Math.max(totalPages - boundaryCount + 1, boundaryCount + 1), totalPages);

    const siblingsStart = Math.max(
        Math.min(
            selectedPageNumber - siblingCount,
            totalPages - boundaryCount - siblingCount * 2 - 1
        ),
        boundaryCount + 2
    );

    const siblingsEnd = Math.min(
        Math.max(selectedPageNumber + siblingCount, boundaryCount + siblingCount * 2 + 2),
        endPages[0] - 2
    );

    /* istanbul ignore next */
    const itemList = [
        ...startPages,
        /* eslint-disable */
        ...(siblingsStart > boundaryCount + 2
            ? ['start-ellipsis']
            : boundaryCount + 1 < totalPages - boundaryCount
            ? [boundaryCount + 1]
            : []),
        ...range(siblingsStart, siblingsEnd),
        ...(siblingsEnd < totalPages - boundaryCount - 1
            ? ['end-ellipsis']
            : totalPages - boundaryCount > boundaryCount
            ? [totalPages - boundaryCount]
            : []),

        ...endPages,
    ];

    return itemList;
};

export const getPageItemsMobile = (totalPages, selectedPageNumber) => {
    const firstPageCount = 4;
    let itemList;
    /* istanbul ignore next */
    if (totalPages <= firstPageCount) {
        itemList = range(1, Math.min(firstPageCount, totalPages));
    } else {
        itemList = [
            ...(selectedPageNumber < (totalPages - 1) ? [selectedPageNumber] : [1]),
            ...(totalPages > firstPageCount ? ['start-ellipsis'] : []),
            ...(selectedPageNumber ? range(totalPages - 1, totalPages) : []),
        ];
    }

    return itemList;
};
