import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames/bind';
import Button from 'yoda-core-components/lib/components/Button';
import time from 'yoda-core-components/lib/moment/Time';
import { dt } from 'yoda-core-components/lib/helpers/Utils/GetTailwindToken';
import { bonusIconConstants, masterCardHistory } from '../../common/Constants';
import * as styles from './History.css';

const cx = classNames.bind(styles);

const ActivityHistoryData = ({ imagePath, data, isDesktop, isMobile, onGetDetails }) => {
    const history = [];
    let bonusImage;
    let bonusData;
    const tableCellCommonStyles = dt([
        'border-b',
        'border-solid',
        'border-gray-15',
        'table-cell',
        'py-3',
        'px-2.5',
        'min-w-[auto]',
        'max-w-[250px]',
        'smMd:min-w-[50px]',
        'first:smMd:min-w-[50px]',
        'text-small',
        'text-black',
        'align-middle',
    ]);
    if (data && data.length > 0) {
        for (let i = 0; i < data.length; i += 1) {
            const bonusIconTypes = [];
            const isMissingPointsData = data[i]?.typeDescription === masterCardHistory;
            if (data[i].bonusIconTypes && data[i].bonusIconTypes.length) {
                for (let j = 0; j < data[i].bonusIconTypes.length; j += 1) {
                    if (data[i].bonusIconTypes[j].type) {
                        bonusData = bonusIconConstants;
                        if (isDesktop && bonusData[data[i].bonusIconTypes[j].type]) {
                            bonusImage = `${imagePath}${bonusData[data[i].bonusIconTypes[j].type]}`;
                        } else if (!isDesktop && bonusData[data[i].bonusIconTypes[j].type]) {
                            bonusImage = `${bonusData[data[i].bonusIconTypes[j].type]}`;
                        } else {
                            bonusImage = undefined;
                        }
                        bonusIconTypes.push(
                            <div>
                                {bonusImage && (
                                    <img
                                        src={bonusImage}
                                        title={data[i].bonusIconTypes[j].iconDescription}
                                        alt=""
                                    />
                                )}
                            </div>
                        );
                        if (isMobile) {
                            bonusIconTypes.push(<br />);
                        }
                    }
                }
            }
            let renderRewardTypeDescription = <></>;
            if (isMissingPointsData) {
                renderRewardTypeDescription = (
                    <>
                        <div>{data[i]?.typeDescription}</div>
                        <div>{data[i]?.bonusIconTypes[0]?.iconDescription}</div>
                    </>
                );
            } else {
                renderRewardTypeDescription = (
                    <div>{data[i].description.payment.typeDescription}</div>
                );
            }
            history.push(
                <div
                    className={`${cx('divTableRow', 'activityHistoryData')} ${dt([
                        'table-row',
                        'bg-white',
                        'h-8',
                    ])}`}
                >
                    <div
                        className={`${cx('divTableCell', tableCellCommonStyles)} ${dt(['!px-4'])}`}
                    >
                        <div>{time(data[i].date).format('MMM DD, YYYY')}</div>
                        {data[i].id ? (
                            <Button
                                size="Sm"
                                className={dt(['lg:hidden', 'pt-1', 'mt-1'])}
                                buttonType="Link"
                                onClick={(e) => onGetDetails(data[i], e)}
                            >
                                {data[i].typeDescription}
                            </Button>
                        ) : (
                            <div className={dt(['lg:hidden', 'pt-1'])}>
                                {data[i].typeDescription}
                            </div>
                        )}
                        <div
                            className={`${cx('rewardsCertDetails')} ${dt(['md:!hidden', 'pt-1'])}`}
                        >
                            {data[i].id ? (
                                <div>
                                    <div className={dt(['text-gray-70'])}>
                                        {data[i].description.channel}
                                    </div>
                                </div>
                            ) : (
                                renderRewardTypeDescription
                            )}
                        </div>
                    </div>
                    <div
                        className={`${cx('divTableCell', tableCellCommonStyles)} ${dt([
                            'smMd:!hidden',
                        ])}`}
                    >
                        {data[i].id ? (
                            <Button
                                automationId="at-activity-transaction-details"
                                className={`${cx('transactionDetailsButton')} ${dt(['text-left'])}`}
                                buttonType="Link"
                                size="Sm"
                                onClick={(e) => onGetDetails(data[i], e)}
                            >
                                {data[i].typeDescription}
                            </Button>
                        ) : (
                            <div className={dt(['pt-1'])}>{data[i].typeDescription}</div>
                        )}
                    </div>
                    <div
                        className={`${cx('divTableCell', tableCellCommonStyles)} ${dt([
                            'smOnly:!hidden',
                        ])}`}
                    >
                        {data[i].id ? (
                            <div className={`${cx('divTableDesc')} ${dt(['text-gray-70'])}`}>
                                {data[i].description.channel && (
                                    <div>{data[i].description.channel}</div>
                                )}
                            </div>
                        ) : (
                            <div>
                                {isMissingPointsData
                                    ? data[i]?.bonusIconTypes[0]?.iconDescription
                                    : data[i].description.payment.typeDescription}
                            </div>
                        )}
                    </div>
                    <div
                        className={`${cx('divTableCell', tableCellCommonStyles)} ${dt([
                            'text-center',
                            'bg-light-white',
                            'font-semibold',
                        ])}`}
                    >
                        <div>{data[i].points}</div>
                    </div>
                </div>
            );
        }
    }

    return <div className={`${cx('divTableBody')} ${dt(['table-row-group'])}`}>{history}</div>;
};

ActivityHistoryData.defaultProps = {
    imagePath: '',
};

ActivityHistoryData.propTypes = {
    data: PropTypes.objectOf.isRequired,
    imagePath: PropTypes.string,
    isMobile: PropTypes.objectOf.isRequired,
    isDesktop: PropTypes.objectOf.isRequired,
    onGetDetails: PropTypes.func.isRequired,
};

export default ActivityHistoryData;
