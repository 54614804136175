import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import time from 'yoda-core-components/lib/moment/Time';
import { dt } from 'yoda-core-components/lib/helpers/Utils/GetTailwindToken';
import * as styles from '../History.css';

const cx = classNames.bind(styles);

const MissingPointsDetails = ({ missingPointsHistory = {}, messagesTexts }) => {
    const missingData = [];
    const statusFilter = (status) => {
        const statusValue = messagesTexts.missingPointsHistory.typeStatus[status];
        let colorClass = statusValue;
        if (colorClass === 'Already Awarded') {
            colorClass = 'Already';
        }
        return <div id={cx(`missing${colorClass}`)}>{statusValue}</div>;
    };
    const tableCellCommonStyles = dt([
        'border-b',
        'border-solid',
        'border-gray-15',
        'table-cell',
        'py-3',
        'px-2.5',
        'min-w-[auto]',
        'max-w-[250px]',
        'smMd:min-w-[50px]',
        'text-small',
        'text-black',
        'align-middle',
    ]);
    if (missingPointsHistory.data && missingPointsHistory.data.length > 0) {
        for (let i = 0; i < missingPointsHistory.data.length; i += 1) {
            missingData.push(
                <div
                    className={`${cx(
                        'divTableRow',
                        'activityHistoryData',
                        'missingActivityData'
                    )} ${dt(['w-full', 'table-row', 'bg-white', 'h-8'])}`}
                >
                    <div
                        className={`${cx('divTableCell', tableCellCommonStyles)} ${dt(['!px-4'])}`}
                    >
                        <div>
                            {time(missingPointsHistory.data[i].requestDate).format('MMM DD, YYYY')}
                        </div>
                        <div className={dt(['lg:hidden', 'pt-1'])}>
                            {missingPointsHistory.data[i].receiptId}
                        </div>
                        <div
                            className={`${cx('icon', 'inlineClass', 'missingStatus')} ${dt([
                                'md:!hidden',
                                'pt-1',
                            ])}`}
                        >
                            {statusFilter(missingPointsHistory.data[i].transactionStatus)}
                        </div>
                    </div>
                    <div
                        className={`${cx('divTableCell', tableCellCommonStyles)} ${dt([
                            'smMd:!hidden',
                        ])}`}
                    >
                        <div>
                            <div>{missingPointsHistory.data[i].receiptId}</div>
                        </div>
                    </div>
                    <div
                        className={`${cx('divTableCell', tableCellCommonStyles)} ${dt([
                            'smOnly:!hidden',
                        ])}`}
                    >
                        <div className={cx('icon', 'inlineClass', 'missingStatus')}>
                            {statusFilter(missingPointsHistory.data[i].transactionStatus)}
                        </div>
                    </div>
                    <div
                        className={`${cx('divTableCell', tableCellCommonStyles)} ${dt([
                            'text-center',
                            'bg-light-white',
                            'font-semibold',
                        ])}`}
                    >
                        <div>${missingPointsHistory.data[i].receiptAmount}</div>
                    </div>
                </div>
            );
        }
    }

    return <div className={`${cx('divTableBody')} ${dt(['table-row-group'])}`}>{missingData}</div>;
};

MissingPointsDetails.propTypes = {
    missingPointsHistory: PropTypes.objectOf(PropTypes.object).isRequired,
    messagesTexts: PropTypes.objectOf(PropTypes.object).isRequired,
};

export default MissingPointsDetails;
