export const GET_REWARDS_REQUEST = 'GET_REWARDS_REQUEST';
export const GET_PROFILE_ERROR = 'GET_PROFILE_ERROR';
export const GET_POINT_REQUEST = 'GET_POINT_REQUEST';
export const GET_REWARDS_REQUEST_SUCCESS = 'GET_REWARDS_REQUEST_SUCCESS';
export const GET_POINT_REQUEST_SUCCESS = 'GET_POINT_REQUEST_SUCCESS';
export const GET_POINT_REQUEST_ERROR = 'GET_POINT_REQUEST_ERROR';
export const GET_REWARDS_REQUEST_ERROR = 'GET_REWARDS_REQUEST_ERROR';
export const REWARDS_PAGE_LOADED_SUCCESS = 'REWARDS_PAGE_LOADED_SUCCESS';
export const GET_FOOTER_REQUEST_SUCCESS = 'GET_FOOTER_REQUEST_SUCCESS';
export const REWARDS_CERTIFICATE_SELECTION = 'REWARDS_CERTIFICATE_SELECTION';
export const GET_HEADER_REQUEST = 'GET_HEADER_REQUEST';
export const GET_HEADER_REQUEST_SUCCESS = 'GET_HEADER_REQUEST_SUCCESS';
export const GET_HEADER_REQUEST_ERROR = 'GET_HEADER_REQUEST_ERROR';
export const GET_FOOTER_REQUEST = 'GET_FOOTER_REQUEST';
export const GET_FOOTER_REQUEST_ERROR = 'GET_FOOTER_REQUEST_ERROR';
export const LINK_CREDITCARD = 'LINK_CREDITCARD';
export const LINK_CREDITCARD_SUCCESS = 'LINK_CREDITCARD_SUCCESS';
export const LINK_CREDITCARD_ERROR = 'LINK_CREDITCARD_ERROR';
export const NAV_PAGE_SELECETION = 'NAV_PAGE_SELECETION';
export const UPDATE_REWARDS_SETTINGS = 'UPDATE_REWARDS_SETTINGS';
export const UPDATE_REWARDS_SETTINGS_SUCCESS = 'UPDATE_REWARDS_SETTINGS_SUCCESS';
export const UPDATE_REWARDS_SETTINGS_ERROR = 'UPDATE_REWARDS_SETTINGS_ERROR';
export const REWARDS_PROFILE_UPDATE_SUCCESS = 'REWARDS_PROFILE_UPDATE_SUCCESS';
export const REWARDS_DATA_TRACKER = 'REWARDS_DATA_TRACKER';
export const ACCOUNT_CLEAR_MISSING_POINTS = 'ACCOUNT_CLEAR_MISSING_POINTS';
export const TRACK_OPEN_MISSING_MODAL = 'TRACK_OPEN_MISSING_MODAL';
