import * as types from '../actionTypes/LaunchNLearnActionTypes';

export default function LaunchNLearnReducer(state = {}, action = {}) {
    switch (action.type) {
        case types.SEND_AUTH_EMAIL: {
            return { ...state, authEmail: { isLoading: true } };
        }
        case types.SEND_AUTH_EMAIL_ERROR:
        case types.SEND_AUTH_EMAIL_SUCCESS: {
            const authEmail = action.payload;
            return { ...state, authEmail: { isLoading: false, ...authEmail } };
        }
        default:
            return state;
    }
}

LaunchNLearnReducer.reducerName = 'launchNLearnDetails';
