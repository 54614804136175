import BaseActionFilter from 'yoda-core-components/lib/helpers/Analytics/BaseActionFilter';
import Cookies from 'yoda-core-components/lib/helpers/Cookies/Cookies';
import { ACCOUNT_ID } from 'yoda-site-components/lib/common/Constants';
import TokenProvider from 'yoda-core-components/lib/helpers/TokenProvider/TokenProvider';

class LaunchNLearnActionFilter extends BaseActionFilter {
    /* istanbul ignore next */
    // eslint-disable-next-line class-methods-use-this
    getLaunchNLearnLoadPL(store) {
        const { context } = store.getState();
        let deviceType = '';
        if (context.deviceType.isMobile) {
            deviceType = 'mobile';
        } else if (context.deviceType.isDesktop) {
            deviceType = 'desktop';
        } else {
            deviceType = 'tablet';
        }

        /* istanbul ignore next */
        const profileID = TokenProvider.get(ACCOUNT_ID) || '';
        const getLnLDL = this.updateCommonDigitalData();
        /* istanbul ignore next */
        const associateFlag = getLnLDL.user.profile.profileInfo.associateFlag || 'no';
        getLnLDL.page.pageInfo.pageName = 'rewards launch n learn';
        getLnLDL.page.pageInfo.pageType = 'rewards';
        getLnLDL.page.pageInfo.siteSection = 'rewards';
        getLnLDL.page.pageInfo.deviceType = deviceType;
        getLnLDL.page.pageInfo.searchRedirect = '';
        getLnLDL.page.pageInfo.releaseVersion = context.__RELEASEVERSION__;
        getLnLDL.page.pageInfo.version = 'apollo 1 2017 redesign yoda account'; // TODO when we have multiple template
        getLnLDL.page.pageInfo.clusterInstance = Cookies.load('DPInstance');
        getLnLDL.page.category = {};
        getLnLDL.page.category.primaryCategory = 'account';
        getLnLDL.page.category.subCategory1 = '';
        getLnLDL.page.category.subCategory2 = '';
        getLnLDL.page.category.department = 'account';
        getLnLDL.user.profile.profileInfo.profileID = profileID;
        let rewardsFlag = 'no';
        /* istanbul ignore next */
        const rewardTier =
            TokenProvider.get('DP_REWARDS_TIER') ||
            getLnLDL.user.profile.profileInfo.rewardsMemberType;
        if (rewardTier && rewardTier !== 'no rewards account') {
            rewardsFlag = 'yes';
        }
        getLnLDL.user.profile.profileInfo.rewardsMemberType = rewardTier; // silver gold platinum bronze red pending
        getLnLDL.user.profile.profileInfo.rewardsFlag = rewardsFlag;
        getLnLDL.user.profile.profileInfo.associateFlag = associateFlag; // yes or no
        getLnLDL.error = [
            {
                errorDescription: '',
            },
        ];
        return getLnLDL;
    }

    /* istanbul ignore next */
    getLnLAccountFormLoadPL(store, pageName) {
        const { context } = store.getState();

        let deviceType = '';
        if (context.deviceType.isMobile) {
            deviceType = 'mobile';
        } else if (context.deviceType.isDesktop) {
            deviceType = 'desktop';
        } else {
            deviceType = 'tablet';
        }

        /* istanbul ignore next */
        const profileID = TokenProvider.get(ACCOUNT_ID) || '';

        const getLnLDLAccount = this.updateCommonDigitalData();
        /* istanbul ignore next */
        const associateFlag = getLnLDLAccount.user.profile.profileInfo.associateFlag || 'no';
        getLnLDLAccount.page.pageInfo.pageName = pageName;
        getLnLDLAccount.page.pageInfo.pageType = 'account';
        getLnLDLAccount.page.pageInfo.siteSection = 'account';
        getLnLDLAccount.page.pageInfo.deviceType = deviceType;
        getLnLDLAccount.page.pageInfo.searchRedirect = '';
        getLnLDLAccount.page.pageInfo.releaseVersion = context.__RELEASEVERSION__;
        getLnLDLAccount.page.pageInfo.version = 'apollo 1 2017 redesign yoda account';
        getLnLDLAccount.page.pageInfo.clusterInstance = Cookies.load('DPInstance');
        getLnLDLAccount.page.category = {};
        getLnLDLAccount.page.category.primaryCategory = 'account';
        getLnLDLAccount.page.category.subCategory1 = '';
        getLnLDLAccount.page.category.subCategory2 = '';
        getLnLDLAccount.page.category.department = 'account';
        getLnLDLAccount.user.profile.profileInfo.profileID = profileID;
        /* istanbul ignore next */
        const rewardTier =
            TokenProvider.get('DP_REWARDS_TIER') ||
            getLnLDLAccount.user.profile.profileInfo.rewardsMemberType;
        getLnLDLAccount.user.profile.profileInfo.rewardsMemberType = rewardTier; // silver gold platinum bronze red pending
        getLnLDLAccount.user.profile.profileInfo.associateFlag = associateFlag; // yes or no
        getLnLDLAccount.error = [
            {
                errorDescription: '',
            },
        ];
        return getLnLDLAccount;
    }

    /* istanbul ignore next */
    // eslint-disable-next-line class-methods-use-this
    getLaunchNLearnFormErrorPL(error) {
        const getLnLDL = {
            error,
        };
        return getLnLDL;
    }
}

export default LaunchNLearnActionFilter;
