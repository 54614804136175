import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames/bind';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import Icon from 'yoda-core-components/lib/components/Icon/Icon';
import IconBonus from 'yoda-core-components/lib/assets/svg/IconBonus.svg';
import IconBra from 'yoda-core-components/lib/assets/svg/IconBra.svg';
import IconReward from 'yoda-core-components/lib/assets/svg/IconReward.svg';
import IconShoe from 'yoda-core-components/lib/assets/svg/IconShoe.svg';
import { dt } from 'yoda-core-components/lib/helpers/Utils/GetTailwindToken';
import time from 'yoda-core-components/lib/moment/Time';
import * as styles from './TransactionDetails.css';

const cx = classNames.bind(styles);

const TransactionDetails = (props) => {
    const { data, messagesTexts, preferences } = props;
    const preferencesTransactionDetails = get(
        preferences,
        'rewardsDashboard.transactionDetails',
        {}
    );
    const messagesTextsTransactionDetails = get(
        messagesTexts,
        'rewardsDashboard.transactionDetails',
        {}
    );
    if (isEmpty(data) || data.type === undefined) return null;

    const location = get(data, 'description.channel', data.location);
    const amountEarningPoints = get(data, 'description.eligibleAmount');
    const paymentTypeDescription = get(data, 'description.payment.typeDescription', '');
    const ruleLabel = get(data, 'description.payment.ruleLabel', '');
    // fallback commented : preferencesTransactionDetails.earningPointsRule[paymentTypeDescription]
    const isMerchantReturn = data.type.toUpperCase() === 'MERCHANDISE_RETURN';
    const isCertificate = data.type.toUpperCase() === 'CERTIFICATE';
    const {
        url: bonusDetailsUrl,
        openInNewTab: detailsLinkOpenInNewTab,
    } = preferencesTransactionDetails.bonusDetails;
    const detailsLinkTarget = detailsLinkOpenInNewTab ? '_blank' : null;
    const bonusIconTypesMapping = {
        'icon-bonus': <IconBonus />,
        'icon-bra': <IconBra />,
        'icon-reward': <IconReward />,
        'icon-shoe': <IconShoe />,
    };
    const showBonus =
        data.bonusIconTypes &&
        data.bonusIconTypes[0] &&
        !isMerchantReturn &&
        !isCertificate &&
        Object.keys(data.bonusIconTypes[0]).length !== 0;
    return (
        <div className={`${cx('transDetail')} ${dt(['mt-4'])}`}>
            <div className={cx('transDetailContent')}>
                <div className={`${cx('transDetailBlock')} ${dt(['pt-4', !showBonus && '!pb-4'])}`}>
                    <div className={`${cx('typeDescription')} ${dt(['text-xlarge', 'mb-1'])}`}>
                        {data.typeDescription}
                    </div>
                    <div className={`${cx('paymentDateChannel')} ${dt(['!text-normal'])}`}>
                        {time(data.date).format('MMM DD, YYYY')}
                        {location && ` at ${location}`}
                    </div>
                </div>
                {showBonus && (
                    <div
                        className={`${cx('transDetailBlock', 'eventTypeContainer')} ${dt([
                            '!mt-2',
                            '!mb-2',
                        ])}`}
                    >
                        {data.bonusIconTypes.map((bonusicon) => (
                            <div className={cx('eventType')}>
                                <h3 className={cx('eventIconType')}>
                                    <Icon
                                        iconType="svg"
                                        className={cx('eventIcon')}
                                        width="24px"
                                        height="24px"
                                        viewBox="0 0 24 24"
                                    >
                                        {
                                            bonusIconTypesMapping[
                                                preferencesTransactionDetails.bonusIconTypes[
                                                    bonusicon.type
                                                ]
                                            ]
                                        }
                                    </Icon>
                                    {bonusicon.type}
                                </h3>
                                {bonusicon.iconDescription && (
                                    <div
                                        className={`${cx('eventIconDescription')} ${dt([
                                            'mt-1',
                                            'mb-2',
                                        ])}`}
                                    >
                                        {bonusicon.iconDescription}
                                        <a
                                            href={bonusDetailsUrl}
                                            target={detailsLinkTarget}
                                            className={cx('getDetails')}
                                        >
                                            {messagesTextsTransactionDetails.bonusDetailsLinkText}
                                        </a>
                                    </div>
                                )}
                            </div>
                        ))}
                    </div>
                )}
            </div>
            {data.items && data.items.length > 0 && (
                <div
                    className={`${cx('transDetailContent', 'itemListContainer')} ${dt(['!pt-4'])}`}
                >
                    {data.items.map((item) =>
                        item.status && item.status.toUpperCase() === 'ELIGIBLE' ? (
                            <div className={cx('transDetailBlock', 'eligibleItem')}>
                                <div className={cx('prodName')}>{item.description}</div>
                                <div className={cx('prodAmount')}>
                                    {isMerchantReturn || isCertificate
                                        ? messagesTextsTransactionDetails.minus
                                        : ''}
                                    ${item.eligibleAmount || item.amount}
                                </div>
                            </div>
                        ) : (
                            <div className={cx('transDetailBlock', 'nonEligibleItem')}>
                                <div className={cx('prodName')}>{item.description}</div>
                                <div className={cx('notEligibleItemWarning')}>
                                    {messagesTextsTransactionDetails.notEligibleItemWarning}
                                </div>
                            </div>
                        )
                    )}
                    {!isCertificate && (
                        <div className={cx('transDetailBlock', 'totalAmountEarningContainer')}>
                            <div className={cx('earningDeductedPoints')}>
                                {isMerchantReturn
                                    ? messagesTextsTransactionDetails.amountDeductedTitle
                                    : messagesTextsTransactionDetails.amountEarningPointsTitle}
                            </div>
                            <div className={cx('totalAmountEarning')}>
                                {isMerchantReturn ? '-' : ''}${amountEarningPoints}
                            </div>
                        </div>
                    )}
                </div>
            )}
            {!isCertificate && !isMerchantReturn && (
                <div
                    className={`${cx('transDetailContent', 'paymentRuleContainer')} ${dt([
                        '!border-b-0',
                    ])}`}
                >
                    <div className={`${cx('transDetailBlock')} ${dt(['!pb-0'])}`}>
                        <h3 className={`${cx('paymentTitle')} ${dt(['mt-4'])}`}>
                            {isMerchantReturn
                                ? messagesTextsTransactionDetails.originalPaymentTitle
                                : messagesTextsTransactionDetails.paymentTitle}
                        </h3>
                        <div className={`${cx('paymentType')} ${dt(['py-1'])}`}>
                            {paymentTypeDescription}
                        </div>
                        <div className={cx('spentPoint')}>{ruleLabel}</div>
                    </div>
                </div>
            )}
            {!isCertificate && (
                <div
                    className={`${cx('transDetailContent', 'pointsSummary')} ${dt([
                        '!border-b-0',
                    ])}`}
                >
                    {data.bonusPoints !== 0 && !isMerchantReturn && (
                        <div className={cx('transDetailBlock')}>
                            <div className={cx('points')}>
                                <div className={cx('rewardPoints')}>
                                    {messagesTextsTransactionDetails.rewardsPointsTitle}
                                </div>
                                <div className={cx('rewardTotalPoints')}>
                                    {data.rewardsPoints}
                                    {messagesTextsTransactionDetails.Points}
                                </div>
                            </div>
                            <div className={cx('points')}>
                                <div className={cx('bonusRewardPoints')}>
                                    {messagesTextsTransactionDetails.bonusPointsTitle}
                                </div>
                                <div className={cx('bonusRewardTotalPoints')}>
                                    {data.bonusPoints}
                                    {messagesTextsTransactionDetails.Points}
                                </div>
                            </div>
                        </div>
                    )}
                    <div className={cx('transDetailBlock')}>
                        <div className={cx('totalPoints')}>
                            <div className={`${cx('total')} ${dt(['!text-large'])}`}>
                                {messagesTextsTransactionDetails.TotalPointsTitle}
                            </div>
                            <div className={`${cx('totalAmount')} ${dt(['!text-large'])}`}>
                                {data.total}
                                {messagesTextsTransactionDetails.Points}
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};
TransactionDetails.propTypes = {
    data: PropTypes.objectOf.isRequired,
    messagesTexts: PropTypes.objectOf.isRequired,
    preferences: PropTypes.objectOf.isRequired,
};

export default TransactionDetails;
