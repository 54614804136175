import { takeLatest, put, call } from 'redux-saga/effects';
import { checkRegistry } from 'yoda-interfaces/lib/Account/AccountApi';
import {
    GET_REGISTRY_REQUEST_SUCCESS,
    GET_REGISTRY_REQUEST_ERROR,
    GET_REGISTRY_REQUEST,
} from 'yoda-site-components/lib/actionTypes/SigninActionTypes';

export function* registrySaga(action) {
    const registryData = yield call(checkRegistry, action.payload);
    if (registryData.status === 201) {
        const defaultError = registryData;
        defaultError.redirectTo = 'giftRegistry';
        yield put({ type: GET_REGISTRY_REQUEST_SUCCESS, registryData: defaultError });
    } else if (!registryData.status) {
        const defaultError = registryData;
        defaultError.redirectTo = 'pdp';
        yield put({
            type: GET_REGISTRY_REQUEST_ERROR,
            registryData: defaultError,
        });
    } else {
        const defaultError = registryData;
        defaultError.redirectTo = 'pdp';
        yield put({
            type: GET_REGISTRY_REQUEST_ERROR,
            registryData: defaultError,
        });
    }
}

export default function* watchRegistrySaga() {
    yield takeLatest(GET_REGISTRY_REQUEST, registrySaga);
}
watchRegistrySaga.sagaName = 'registrySaga';
