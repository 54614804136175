import {
    REWARDS_PAGE_LOADED_SUCCESS,
    GET_REWARDS_REQUEST,
    REWARDS_CERTIFICATE_SELECTION,
    UPDATE_REWARDS_SETTINGS,
    LINK_CREDITCARD,
    NAV_PAGE_SELECETION,
    REWARDS_PROFILE_UPDATE_SUCCESS,
    REWARDS_DATA_TRACKER,
    ACCOUNT_CLEAR_MISSING_POINTS,
    TRACK_OPEN_MISSING_MODAL,
} from '../actionTypes/RewardsActionTypes';

export const rewardsPageLoadedAction = (pageName) => ({
    type: REWARDS_PAGE_LOADED_SUCCESS,
    payload: pageName,
});

export const getRewards = (isDesktop, redirect, enableThirdPartyCoupons) => ({
    type: GET_REWARDS_REQUEST,
    payload: { isDesktop, redirect, enableThirdPartyCoupons },
});

export const getCertificatesSel = (isExpire, popup, useRewards) => ({
    type: REWARDS_CERTIFICATE_SELECTION,
    payload: { isExpire, popup, useRewards },
});

export const updateRewardsSettings = (payload, userAction) => ({
    type: UPDATE_REWARDS_SETTINGS,
    payload,
    userAction,
});

export const linkCreditCard = (payload) => ({
    type: LINK_CREDITCARD,
    payload,
});

export const getRewardsNavPanelData = (data) => ({
    type: NAV_PAGE_SELECETION,
    payload: data,
});

export const rewardsProfileUpdateAction = (data) => ({
    type: REWARDS_PROFILE_UPDATE_SUCCESS,
    payload: data,
});

export const rewardsDataTrackerAction = (data) => ({
    type: REWARDS_DATA_TRACKER,
    payload: data,
});

export const clearMissingPoints = () => ({
    type: ACCOUNT_CLEAR_MISSING_POINTS,
});

export const missingPointModalEvent = () => ({
    type: TRACK_OPEN_MISSING_MODAL,
});
