import PropTypes from 'prop-types';
import React, { Component } from 'react';
import classNames from 'classnames/bind';
import get from 'lodash/get';
import LayoutHelmet from 'yoda-site-components/lib/components/CAMNav/LayoutHelmet';
import CertonaZone from 'yoda-site-components/lib/components/CertonaZone/CertonaZone';
import History from '../../../../../components/History/History';
import * as styles from './RewardsHomeScreenLegacy.css';
import { getImagePath } from '../../../../../common/utils';
import RewardsWelcomeBannerAndPoints from '../RewardsWelcomeBannerAndPoints/RewardsWelcomeBannerAndPointsConnectLazy';

const cx = classNames.bind(styles);

class RewardsHomeScreenLegacy extends Component {
    static defaultProps = {
        location: {
            query: {
                desktop: false,
            },
            pathname: '',
        },
        rewardsDetails: {
            data: '',
            certificates: { data: [] },
        },
        isNative: false,
        patchHeader: {},
    };

    static propTypes = {
        actions: PropTypes.objectOf(PropTypes.func).isRequired,
        historyActions: PropTypes.objectOf(PropTypes.func).isRequired,
        historyDetails: PropTypes.objectOf(PropTypes.object).isRequired,
        deviceType: PropTypes.objectOf.isRequired,
        rewardsDetails: PropTypes.object,
        scrollToDisclosure: PropTypes.func.isRequired,
        preferences: PropTypes.objectOf.isRequired,
        featureFlags: PropTypes.objectOf.isRequired,
        location: PropTypes.object,
        isRewardsHistoryTabEnabled: PropTypes.bool.isRequired,
        isActivityHistoryTabEnabled: PropTypes.bool.isRequired,
        isMissingPointsEnabled: PropTypes.bool.isRequired,
        messagesTexts: PropTypes.objectOf(PropTypes.object).isRequired,
        showLoader: PropTypes.bool.isRequired,
        missingPointsHistory: PropTypes.objectOf(PropTypes.object).isRequired,
        activityHistoryDetails: PropTypes.objectOf(PropTypes.object).isRequired,
        transactionDetails: PropTypes.objectOf(PropTypes.object).isRequired,
        isNative: PropTypes.bool,
        patchHeader: PropTypes.func,
    };

    /* istanbul ignore next */
    constructor(props, context) {
        super(props, context);
        this.state = {
            layoutTitle: { title: 'Rewards Center' },
            /* History properties */
            activityCurrentPage: 1,
            activityEndNo: 0,
            activityStartNo: 0,
            activityFilterDates: [],
            rewardsCurrentPage: 1,
            missingCurrentPage: 1,
            missingEndNo: 0,
            missingStartNo: 0,
            rewardsEndNo: 0,
            rewardsStartNo: 0,
            rewardsFilterDates: [],
        };
    }

    componentDidMount() {
        const {
            actions: { rewardsPageLoadedAction },
        } = this.props;
        rewardsPageLoadedAction('rewards');
    }

    /* eslint-disable camelcase */
    /* istanbul ignore next */
    UNSAFE_componentWillReceiveProps(nextProps) {
        const { historyDetails } = nextProps;
        this.prepareRewardsHistory(historyDetails);
    }

    /**
     * Set the starting page, the current page, and the ending page, for Rewards
     * or Activity history.
     * @param {Object} details - Configuration for the new page count set.
     * @param {number} id -
     *  0 - Context: Activity History
     *  1 - Context: Rewards History
     */

    /**
     * Set the current and end page of either Rewards or Activity history.
     * @param {number} id -
     *  0 - Context: Activity History
     *  1 - Context: Rewards History
     */

    /**
     * Given relevant history details, adjust the History component to reflect the
     * current page, as well as set the filter dates.
     * @param {Object} historyDetails - Relevant details for the History component.
     */
    prepareRewardsHistory = (historyDetails) => {
        if (!historyDetails) return false;

        const { filterDates, rewardsHistory } = historyDetails;

        if (rewardsHistory) {
            filterDates && this.setState({ rewardsFilterDates: filterDates });
            return true;
        }

        return false;
    };

    render() {
        const {
            deviceType,
            rewardsDetails,
            scrollToDisclosure,
            preferences,
            featureFlags,
            location,
            historyActions,
            historyDetails,
            isRewardsHistoryTabEnabled,
            isActivityHistoryTabEnabled,
            isMissingPointsEnabled,
            messagesTexts,
            showLoader,
            missingPointsHistory,
            activityHistoryDetails,
            transactionDetails,
            isNative,
            patchHeader,
        } = this.props;
        const {
            layoutTitle,
            activityCurrentPage,
            activityEndNo,
            activityStartNo,
            activityFilterDates,
            rewardsCurrentPage,
            missingCurrentPage,
            missingEndNo,
            missingStartNo,
            rewardsEndNo,
            rewardsStartNo,
            rewardsFilterDates,
        } = this.state;
        const slotType = 'rewards3_rr';
        const { isDesktop } = deviceType;
        const imagePath = getImagePath(isDesktop);
        const hideCertonaZone = get(featureFlags, 'enableRewardsWebview', true) && isNative;
        return (
            <>
                <LayoutHelmet layoutState={layoutTitle} />
                <div>
                    {rewardsDetails.data && (
                        <div>
                            <RewardsWelcomeBannerAndPoints
                                scrollToDisclosure={scrollToDisclosure}
                                location={location}
                            />
                            <div>
                                <History
                                    actions={historyActions}
                                    activityCurrentPage={activityCurrentPage}
                                    activityEndNo={activityEndNo}
                                    activityStartNo={activityStartNo}
                                    activityFilterDates={activityFilterDates}
                                    activityHistoryDetails={activityHistoryDetails}
                                    transactionDetails={transactionDetails}
                                    deviceType={deviceType}
                                    historyDetails={historyDetails}
                                    imagePath={imagePath}
                                    isRewardsHistoryTabEnabled={
                                        isRewardsHistoryTabEnabled ||
                                        !!(!isActivityHistoryTabEnabled && !isMissingPointsEnabled)
                                    }
                                    isActivityHistoryTabEnabled={isActivityHistoryTabEnabled}
                                    isMissingPointsEnabled={isMissingPointsEnabled}
                                    rewardsCurrentPage={rewardsCurrentPage}
                                    missingCurrentPage={missingCurrentPage}
                                    rewardsEndNo={rewardsEndNo}
                                    rewardsStartNo={rewardsStartNo}
                                    rewardsFilterDates={rewardsFilterDates}
                                    preferences={preferences}
                                    messagesTexts={messagesTexts}
                                    featureFlags={featureFlags}
                                    showLoader={showLoader}
                                    missingPointsHistory={missingPointsHistory}
                                    missingPointsDetails={{}}
                                    missingEndNo={missingEndNo}
                                    missingStartNo={missingStartNo}
                                    isNative={isNative}
                                    patchHeader={patchHeader}
                                />
                            </div>

                            {!featureFlags.disableRewardsRecZone && !hideCertonaZone && (
                                <div className={cx('itemsViewed')}>
                                    <div className={cx('sm12', 'md12', 'lg12', 'xl12')}>
                                        <CertonaZone
                                            page="ACCOUNT"
                                            slotDetails={{
                                                pageType: 'REWARDS',
                                                attributes: {
                                                    schemes: `${slotType}`,
                                                    recommendations: true,
                                                },
                                                forceRefresh: true,
                                                loader: 'certona',
                                                slotId: `${slotType}`,
                                            }}
                                            schemasToRender={[`${slotType}`]}
                                        />
                                    </div>
                                </div>
                            )}
                        </div>
                    )}
                </div>
            </>
        );
    }
}
export default RewardsHomeScreenLegacy;
