import BaseActionFilter from 'yoda-core-components/lib/helpers/Analytics/BaseActionFilter';
import Cookies from 'yoda-core-components/lib/helpers/Cookies/Cookies';
import { ACCOUNT_ID } from 'yoda-site-components/lib/common/Constants';
import TokenProvider from 'yoda-core-components/lib/helpers/TokenProvider/TokenProvider';
import constants from './RewardsConfig';

class RewardsActionFilter extends BaseActionFilter {
    getRewardsPageDL(store) {
        const { context } = store.getState();
        let deviceType = '';
        if (context.deviceType.isMobile) {
            deviceType = 'mobile';
        } else if (context.deviceType.isDesktop) {
            deviceType = 'desktop';
        } else {
            deviceType = 'tablet';
        }
        /* istanbul ignore next */
        const profileID = TokenProvider.get(ACCOUNT_ID) || '';
        const rewardsPageDL = this.updateCommonDigitalData();
        /* istanbul ignore next */
        const associateFlag = rewardsPageDL.user.profile.profileInfo.associateFlag || 'no';
        rewardsPageDL.page.pageInfo.pageName = constants.PAGE_NAME;
        rewardsPageDL.page.pageInfo.releaseVersion = context.__RELEASEVERSION__;
        rewardsPageDL.page.pageInfo.version = 'apollo 1 2017 redesign yoda account'; // TODO when we have multiple template
        rewardsPageDL.page.pageInfo.searchRedirect = '';
        rewardsPageDL.page.pageInfo.clusterInstance = Cookies.load('DPInstance');
        rewardsPageDL.page.pageInfo.pageType = constants.WELCOME_PAGE_ID;
        rewardsPageDL.page.pageInfo.siteSection = constants.siteSection.HOME;
        rewardsPageDL.page.pageInfo.deviceType = deviceType;
        rewardsPageDL.page.category = {};
        rewardsPageDL.page.category.primaryCategory = 'account';
        rewardsPageDL.page.category.subCategory1 = '';
        rewardsPageDL.page.category.subCategory2 = '';
        rewardsPageDL.page.category.department = 'account';
        rewardsPageDL.user.profile.profileInfo.profileID = profileID;
        /* istanbul ignore next */
        const rewardTier =
            TokenProvider.get('DP_REWARDS_TIER') ||
            rewardsPageDL.user.profile.profileInfo.rewardsMemberType;
        rewardsPageDL.user.profile.profileInfo.rewardsMemberType = rewardTier; // silver gold platinum bronze red pending
        if (rewardsPageDL.user.profile.profileInfo.mobileOptIn.length === 0) {
            rewardsPageDL.user.profile.profileInfo.mobileOptIn.push('no selection'); // transactional promotional promotional transactional  no selection
        }
        rewardsPageDL.user.profile.profileInfo.associateFlag = associateFlag; // yes or no
        rewardsPageDL.error = [
            {
                errorDescription: '',
            },
        ];
        return rewardsPageDL;
    }

    /* getPrintSelectionData() {
        const rewardsPageDL = {};
        rewardsPageDL.page = {};
        rewardsPageDL.page.pageInfo = {};
        rewardsPageDL.page.pageInfo.name = constants.printSelection.NAME;
        return rewardsPageDL;
    }

    rewardsError(store, errorObj) {
        const context = store.getState().context;
        let deviceType = '';
        if (context.deviceType.isMobile) {
            deviceType = 'mobile';
        } else if (context.deviceType.isDesktop) {
            deviceType = 'desktop';
        } else {
            deviceType = 'tablet';
        }

        const rewardsPageDL = this.updateCommonDigitalData();
        rewardsPageDL.page.pageInfo.pageName = constants.PAGE_NAME;
        rewardsPageDL.page.pageInfo.releaseVersion = context.__RELEASEVERSION__;
        rewardsPageDL.page.pageInfo.version = 'apollo 1 2017 redesign yoda account';
        rewardsPageDL.page.pageInfo.searchRedirect = '';
        rewardsPageDL.page.pageInfo.pageType = constants.WELCOME_PAGE_ID;
        rewardsPageDL.page.pageInfo.siteSection = constants.siteSection.HOME;
        rewardsPageDL.page.pageInfo.deviceType = deviceType;
        rewardsPageDL.page.pageInfo.clusterInstance = Cookies.load('DPInstance');
        rewardsPageDL.page.category = {};
        rewardsPageDL.page.category.primaryCategory = 'account';
        rewardsPageDL.page.category.subCategory1 = '';
        rewardsPageDL.page.category.subCategory2 = '';
        rewardsPageDL.page.category.department = 'account';
        rewardsPageDL.error = [];
        rewardsPageDL.error.push({
            errorDescription: errorObj.data.errorMessage,
        });
        return rewardsPageDL;
    } */

    /* istanbul ignore next */
    // eslint-disable-next-line class-methods-use-this
    getCertificateSelectionData(store, willExpire) {
        const rewardsPageDL = {};
        rewardsPageDL.page = {};
        rewardsPageDL.page.pageInfo = {};
        let willExpireName = constants.certificateSelection.NONEXPIRENAME;
        /* istanbul ignore next */
        if (willExpire) {
            willExpireName = constants.certificateSelection.EXPIRENAME;
        }
        rewardsPageDL.page.pageInfo.name = willExpireName;
        return rewardsPageDL;
    }

    getMigrationPL(store, data) {
        const { context } = store.getState();
        let deviceType = '';
        if (context.deviceType.isMobile) {
            deviceType = 'mobile';
        } else if (context.deviceType.isDesktop) {
            deviceType = 'desktop';
        } else {
            deviceType = 'tablet';
        }
        /* istanbul ignore next */
        const profileID = TokenProvider.get(ACCOUNT_ID) || '';

        const getMigrationDL = this.updateCommonDigitalData();
        /* istanbul ignore next */
        const associateFlag = getMigrationDL.user.profile.profileInfo.associateFlag || 'no';
        getMigrationDL.page.pageInfo.pageName = data.pageName;
        getMigrationDL.page.pageInfo.pageType = 'rewards';
        getMigrationDL.page.pageInfo.siteSection = 'rewards';
        getMigrationDL.page.pageInfo.deviceType = deviceType;
        getMigrationDL.page.pageInfo.searchRedirect = '';
        getMigrationDL.page.pageInfo.releaseVersion = context.__RELEASEVERSION__;
        getMigrationDL.page.pageInfo.version = 'apollo 1 2017 redesign yoda account';
        getMigrationDL.page.pageInfo.clusterInstance = Cookies.load('DPInstance');
        getMigrationDL.page.category = {};
        getMigrationDL.page.category.primaryCategory = 'account';
        getMigrationDL.page.category.subCategory1 = '';
        getMigrationDL.page.category.subCategory2 = '';
        getMigrationDL.page.category.department = 'account';
        getMigrationDL.user.profile.profileInfo.profileID = profileID;
        /* istanbul ignore next */
        const rewardTier =
            TokenProvider.get('DP_REWARDS_TIER') ||
            getMigrationDL.user.profile.profileInfo.rewardsMemberType;
        getMigrationDL.user.profile.profileInfo.rewardsMemberType = rewardTier; // silver gold platinum bronze red pending
        getMigrationDL.user.profile.profileInfo.associateFlag = associateFlag; // yes or no
        return getMigrationDL;
    }

    /* istanbul ignore next */
    // eslint-disable-next-line class-methods-use-this
    getMissingOpenModal() {
        const getMissingOpenModal = {
            page: {
                pageInfo: {
                    name: 'missing reward points request - modal',
                },
            },
        };
        return getMissingOpenModal;
    }
}

export default RewardsActionFilter;
