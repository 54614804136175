import BaseActionFilter from 'yoda-core-components/lib/helpers/Analytics/BaseActionFilter';
import Cookies from 'yoda-core-components/lib/helpers/Cookies/Cookies';
import TokenProvider from 'yoda-core-components/lib/helpers/TokenProvider/TokenProvider';
import constants from './RewardsConfig';

class HistoryActionFilter extends BaseActionFilter {
    getHistoryPL(store, pageName) {
        const { context } = store.getState();

        let deviceType = '';
        if (context.deviceType.isMobile) {
            deviceType = 'mobile';
        } else if (context.deviceType.isDesktop) {
            deviceType = 'desktop';
        } else {
            deviceType = 'tablet';
        }

        const rewardsHistoryPageDL = this.updateCommonDigitalData();
        /* istanbul ignore next */
        const associateFlag = rewardsHistoryPageDL.user.profile.profileInfo.associateFlag || 'no';
        rewardsHistoryPageDL.page.pageInfo.pageName = pageName;
        rewardsHistoryPageDL.page.pageInfo.pageType = 'rewards';
        rewardsHistoryPageDL.page.pageInfo.siteSection = 'rewards';
        rewardsHistoryPageDL.page.pageInfo.deviceType = deviceType;
        rewardsHistoryPageDL.page.pageInfo.searchRedirect = '';
        rewardsHistoryPageDL.page.pageInfo.version = 'apollo 1 2017 redesign yoda account';
        rewardsHistoryPageDL.page.pageInfo.releaseVersion = context.__RELEASEVERSION__;
        rewardsHistoryPageDL.page.pageInfo.clusterInstance = Cookies.load('DPInstance');
        rewardsHistoryPageDL.page.category = {};
        rewardsHistoryPageDL.page.category.primaryCategory = 'account';
        rewardsHistoryPageDL.page.category.subCategory1 = '';
        rewardsHistoryPageDL.page.pageInfo.clusterInstance = Cookies.load('DPInstance');
        rewardsHistoryPageDL.page.category.subCategory2 = '';
        rewardsHistoryPageDL.page.category.department = 'account';
        let rewardsFlag = 'no';
        /* istanbul ignore next */
        const rewardTier =
            TokenProvider.get('DP_REWARDS_TIER') ||
            rewardsHistoryPageDL.user.profile.profileInfo.rewardsMemberType;
        /* istanbul ignore next */
        if (rewardTier && rewardTier !== 'no rewards account') {
            rewardsFlag = 'yes';
        }
        rewardsHistoryPageDL.user.profile.profileInfo.rewardsMemberType = rewardTier;
        rewardsHistoryPageDL.user.profile.profileInfo.rewardsFlag = rewardsFlag;
        rewardsHistoryPageDL.user.profile.profileInfo.associateFlag = associateFlag; // yes or no
        rewardsHistoryPageDL.error = [
            {
                errorDescription: '',
            },
        ];

        return rewardsHistoryPageDL;
    }

    getActivityHistoryPL(store) {
        const { context } = store.getState();

        let deviceType = '';
        if (context.deviceType.isMobile) {
            deviceType = 'mobile';
        } else if (context.deviceType.isDesktop) {
            deviceType = 'desktop';
        } else {
            deviceType = 'tablet';
        }
        const activityHistoryPageDL = this.updateCommonDigitalData();
        /* istanbul ignore next */
        const associateFlag = activityHistoryPageDL.user.profile.profileInfo.associateFlag || 'no';
        activityHistoryPageDL.page.pageInfo.pageName = 'activity history';
        activityHistoryPageDL.page.pageInfo.pageType = 'rewards';
        activityHistoryPageDL.page.pageInfo.siteSection = 'rewards';
        activityHistoryPageDL.page.pageInfo.deviceType = deviceType;
        activityHistoryPageDL.page.pageInfo.searchRedirect = '';
        activityHistoryPageDL.page.pageInfo.version = 'apollo 1 2017 redesign yoda account';
        activityHistoryPageDL.page.pageInfo.releaseVersion = context.__RELEASEVERSION__;
        activityHistoryPageDL.page.pageInfo.clusterInstance = Cookies.load('DPInstance');
        activityHistoryPageDL.page.category = {};
        activityHistoryPageDL.page.category.primaryCategory = 'account';
        activityHistoryPageDL.page.category.subCategory1 = '';
        activityHistoryPageDL.page.category.subCategory2 = '';
        activityHistoryPageDL.page.category.department = 'account';
        let rewardsFlag = 'no';
        /* istanbul ignore next */
        const rewardTier =
            TokenProvider.get('DP_REWARDS_TIER') ||
            activityHistoryPageDL.user.profile.profileInfo.rewardsMemberType;
        /* istanbul ignore next */
        if (rewardTier && rewardTier !== 'no rewards account') {
            rewardsFlag = 'yes';
        }
        activityHistoryPageDL.user.profile.profileInfo.rewardsMemberType = rewardTier;
        activityHistoryPageDL.user.profile.profileInfo.rewardsFlag = rewardsFlag;
        activityHistoryPageDL.user.profile.profileInfo.associateFlag = associateFlag; // yes or no
        activityHistoryPageDL.error = [
            {
                errorDescription: '',
            },
        ];

        return activityHistoryPageDL;
    }

    /* istanbul ignore next */
    // eslint-disable-next-line class-methods-use-this
    getHistoryFilterData(store, data) {
        const historyFilter = {
            page: {},
        };
        historyFilter.page.sbResultsInfo = {};
        historyFilter.page.sbResultsInfo.filters = [];
        historyFilter.page.sbResultsInfo.lastFilter = {};
        historyFilter.page.sbResultsInfo.sortByType = data.sortByType;
        historyFilter.page.sbResultsInfo.typeOfSearch = 'recommended';
        historyFilter.page.sbResultsInfo.numberOfResults = `${data.numberOfResults}`;
        historyFilter.page.sbResultsInfo.resultsPageNumber = `${data.resultsPageNumber}`;
        historyFilter.page.sbResultsInfo.resultsPerPage = `${data.resultsPerPage}`;
        return historyFilter;
    }

    getHistoryPaginationData(store, data) {
        const { context } = store.getState();
        let deviceType = '';
        if (context.deviceType.isMobile) {
            deviceType = 'mobile';
        } else if (context.deviceType.isDesktop) {
            deviceType = 'desktop';
        } else {
            deviceType = 'tablet';
        }
        const historyPagination = this.updateCommonDigitalData();
        /* istanbul ignore next */
        const associateFlag = historyPagination.user.profile.profileInfo.associateFlag || 'no';
        historyPagination.page.pageInfo.pageName = data.pageName;
        historyPagination.page.pageInfo.pageType = 'rewards';
        historyPagination.page.pageInfo.siteSection = 'rewards';
        historyPagination.page.pageInfo.deviceType = deviceType;
        historyPagination.page.pageInfo.searchRedirect = '';
        historyPagination.page.pageInfo.version = 'apollo 1 2017 redesign yoda account';
        historyPagination.page.pageInfo.releaseVersion = context.__RELEASEVERSION__;
        historyPagination.page.pageInfo.clusterInstance = Cookies.load('DPInstance');
        historyPagination.page.category = {};
        historyPagination.page.category.primaryCategory = 'account';
        historyPagination.page.category.subCategory1 = '';
        historyPagination.page.pageInfo.clusterInstance = Cookies.load('DPInstance');
        historyPagination.page.category.subCategory2 = '';
        historyPagination.page.category.department = 'account';
        historyPagination.page.sbResultsInfo = {};
        historyPagination.page.sbResultsInfo.filters = [];
        historyPagination.page.sbResultsInfo.lastFilter = {};
        historyPagination.page.sbResultsInfo.searchTerm = '';
        historyPagination.page.sbResultsInfo.viewType = 'list'; // By default it is list, we dont have grid view for this
        historyPagination.page.sbResultsInfo.typeOfSearch = 'recommended';
        historyPagination.page.sbResultsInfo.sortByType = data.sortByType;
        historyPagination.page.sbResultsInfo.numberOfResults = data.numberOfResults;
        historyPagination.page.sbResultsInfo.resultsPageNumber = data.resultsPageNumber;
        historyPagination.page.sbResultsInfo.resultsPerPage = data.resultsPerPage;
        let rewardsFlag = 'no';
        /* istanbul ignore next */
        const rewardTier =
            TokenProvider.get('DP_REWARDS_TIER') ||
            historyPagination.user.profile.profileInfo.rewardsMemberType;
        /* istanbul ignore next */
        if (rewardTier && rewardTier !== 'no rewards account') {
            rewardsFlag = 'yes';
        }
        historyPagination.user.profile.profileInfo.rewardsMemberType = rewardTier;
        historyPagination.user.profile.profileInfo.rewardsFlag = rewardsFlag;
        historyPagination.user.profile.profileInfo.associateFlag = associateFlag; // yes or no
        return historyPagination;
    }

    /* istanbul ignore next */
    // eslint-disable-next-line class-methods-use-this
    getSortByDate(data) {
        const historyFilter = {
            page: {},
        };
        historyFilter.page.sbResultsInfo = {};
        historyFilter.page.sbResultsInfo.filters = [];
        historyFilter.page.sbResultsInfo.lastFilter = {};
        historyFilter.page.sbResultsInfo.sortByType = data.sortByType;
        historyFilter.page.sbResultsInfo.typeOfSearch = 'regular';
        historyFilter.page.sbResultsInfo.numberOfResults = data.numberOfResults;
        historyFilter.page.sbResultsInfo.resultsPageNumber = data.resultsPageNumber;
        historyFilter.page.sbResultsInfo.resultsPerPage = data.resultsPerPage;
        historyFilter.page.sbResultsInfo.viewType = 'list';
        return historyFilter;
    }

    /* istanbul ignore next */
    // eslint-disable-next-line class-methods-use-this
    getRewardsTransactionOpenModalPL(pageName) {
        const openRewardsTransactionModal = {
            page: {
                pageInfo: {
                    name: pageName,
                },
            },
        };
        return openRewardsTransactionModal;
    }

    getMissingPointsDL(store) {
        const { context } = store.getState();

        let deviceType = '';
        if (context.deviceType.isMobile) {
            deviceType = 'mobile';
            /* istanbul ignore else */
        } else if (context.deviceType.isDesktop) {
            deviceType = 'desktop';
        } else {
            deviceType = 'tablet';
        }
        const missingHistoryPageDL = this.updateCommonDigitalData();
        /* istanbul ignore next */
        missingHistoryPageDL.page.pageInfo.pageName = constants.missingPoints.pageName;
        missingHistoryPageDL.page.pageInfo.pageType = 'rewards';
        missingHistoryPageDL.page.pageInfo.siteSection = 'rewards';
        missingHistoryPageDL.page.pageInfo.deviceType = deviceType;
        missingHistoryPageDL.page.pageInfo.searchRedirect = '';
        missingHistoryPageDL.page.pageInfo.version = 'apollo 1 2017 redesign yoda account';
        missingHistoryPageDL.page.pageInfo.releaseVersion = context.__RELEASEVERSION__;
        missingHistoryPageDL.page.category = {};
        missingHistoryPageDL.page.category.primaryCategory = 'account';
        missingHistoryPageDL.page.category.subCategory1 = '';
        missingHistoryPageDL.page.category.subCategory2 = '';
        missingHistoryPageDL.page.category.department = 'account';
        missingHistoryPageDL.error = [
            {
                errorDescription: '',
            },
        ];
        const associateFlag = missingHistoryPageDL.user.profile.profileInfo.associateFlag || 'no';
        missingHistoryPageDL.user.profile.profileInfo.associateFlag = associateFlag;
        return missingHistoryPageDL;
    }
}

export default HistoryActionFilter;
