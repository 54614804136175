import PropTypes from 'prop-types';
import React, { Component } from 'react';
import classNames from 'classnames/bind';
import get from 'lodash/get';
import SlidePanel from 'yoda-account-components/lib/components/SlidePanel/SlidePanel';
import MessageBox from 'yoda-core-components/lib/components/MessageBox/MessageBox';
import { dt } from 'yoda-core-components/lib/helpers/Utils/GetTailwindToken';
import TransactionDetails from './TransactionDetails';
import * as styles from './ActivityHistoryDetails.css';

const cx = classNames.bind(styles);
class ActivityHistoryDetails extends Component {
    static defaultProps = {
        showTransactionDetails: false,
        patchHeader: {},
        isNative: false,
        featureFlags: {},
    };

    static propTypes = {
        activityData: PropTypes.objectOf(PropTypes.object).isRequired,
        getTransactionDetailsAction: PropTypes.func.isRequired,
        showTransactionDetails: PropTypes.bool,
        onCloseTransactionsDetails: PropTypes.func.isRequired,
        transactionDetails: PropTypes.objectOf(PropTypes.object).isRequired,
        messagesTexts: PropTypes.objectOf(PropTypes.object).isRequired,
        preferences: PropTypes.objectOf(PropTypes.object).isRequired,
        patchHeader: PropTypes.func,
        isNative: PropTypes.bool,
        featureFlags: PropTypes.object,
    };

    constructor() {
        super();
        this.state = {
            isLoadingData: true,
            isError: false,
        };
    }

    /* eslint-disable camelcase */
    UNSAFE_componentWillMount() {
        const { showTransactionDetails, getTransactionDetailsAction } = this.props;

        if (showTransactionDetails) {
            getTransactionDetailsAction(this.props.activityData.id);
        }
    }

    /* eslint-disable camelcase */
    UNSAFE_componentWillReceiveProps(nextProps) {
        const response = nextProps.transactionDetails;
        const { preferences } = this.props;
        const preferencesTransactionDetails = get(
            preferences,
            'rewardsDashboard.transactionDetails',
            {}
        );
        const state = {};
        if (response && 'errorCode' in response) {
            const prefErrorMessages = preferencesTransactionDetails.errorMessages;
            const errorMessage = prefErrorMessages[response.errorCode] || prefErrorMessages.DEFAULT;
            state.isError = true;
            state.errorMessage = errorMessage;
        }
        this.setState({ isLoadingData: false, ...state });
    }

    render() {
        const {
            transactionDetails,
            activityData,
            showTransactionDetails,
            onCloseTransactionsDetails,
            messagesTexts,
            preferences,
            patchHeader,
            isNative,
            featureFlags,
        } = this.props;
        const { isError, errorMessage, isLoadingData } = this.state;
        const messagesTextsTransactionDetails = get(
            messagesTexts,
            'rewardsDashboard.transactionDetails',
            {}
        );
        const { sliderPanelBackTitle } = messagesTextsTransactionDetails;
        const enableRewardsWebview = get(featureFlags, 'enableRewardsWebview', true);

        if (!showTransactionDetails) return null;
        return (
            <div>
                <SlidePanel
                    panelTitle={sliderPanelBackTitle}
                    isOpenPanel={showTransactionDetails}
                    isPrimaryCloseButtonRequired
                    onPrimaryCloseButtonClick={onCloseTransactionsDetails}
                    showBackIcon={false}
                    panelTitleClassName={dt(['text-medium', 'smMd:text-large'])}
                    patchHeader={patchHeader}
                    isNative={isNative}
                    shouldHandleNativeBackBtn={enableRewardsWebview}
                >
                    <div className={`${cx('panelContentWrapper')} ${dt(['relative', 'mx-6'])}`}>
                        <MessageBox
                            type="error"
                            title={errorMessage}
                            showMessage={isError}
                            level="section"
                            hasClose={false}
                        />

                        {!isError && !isLoadingData && (
                            <TransactionDetails
                                data={transactionDetails}
                                messagesTexts={messagesTexts}
                                preferences={preferences}
                                activityData={activityData}
                            />
                        )}
                    </div>
                </SlidePanel>
            </div>
        );
    }
}

export default ActivityHistoryDetails;
