import React from 'react';
import classNames from 'classnames/bind';
import { dt } from 'yoda-core-components/lib/helpers/Utils/GetTailwindToken';
import * as styles from './SkeletonBox.css';

const cx = classNames.bind(styles);

const SkeletonBox = () => (
    <div
        className={`${cx('skeletonBox')} ${dt([
            'min-h-[126px]',
            'bg-white',
            'my-2.5',
            'mx-0',
            'p-5',
            'w-full',
        ])}`}
    />
);

SkeletonBox.propTypes = {};

export default SkeletonBox;
